import React, { useEffect, useState, useRef, useReducer } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import Mheader from './Mheader';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { FaCog, FaSignOutAlt } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { TERipple } from 'tw-elements-react';
import axios from 'axios';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  faDice,
  faGamepad,
  faUser,
  faExchangeAlt,
  faHome,
  faUserShield,
  faUsers,
  faVolumeMute,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from './Footer';

// import '@fortawesome/fontawesome-free/css/all.min.css';
function money(amount) {
  var moneyFormat = Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  const dr = '₦' + moneyFormat.split('NGN')[1];
  return dr;
}
const Single = ({ user, setUser, cont, games, settings }) => {
  const game = games[0];
  const [showModal, setShowModal] = useState(false);
  const [box, setBox] = useState(false);
  const [mute, setMute] = useState(false);
  const jwtt = Cookies.get('jwt');
  const openModal = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 16000); // Adjust the time (in milliseconds) according to your needs
  };
  const [pimage, setPimage] = useState('/images/dice.gif');
  const [status, setStatus] = useState('Stake mode');
  const [stake, setStake] = useState(true);
  const [deposit, setDeposit] = useState(false);
  const [newdep, setDep] = useState(1000);
  const [first, setFirst] = useState(false);
  const [newstake, setNewstake] = useState(100);
  const [display, setDisplay] = useState('Staked ' + money(newstake));
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dicea, setDicea] = useState(1);
  const [color, setColor] = useState('fg');
  const [diceb, setDiceb] = useState(1);
  let [pp, setPp] = useState(true);
  const done = '/images/oneee.png';
  const dtwo = '/images/twoo.jfif';
  const dthree = '/images/threes.png';
  const dfour = '/images/ffour.png';
  const dfive = '/images/dice55.jfif';
  const dsix = '/images/six.png';

  const [pactive, setPactive] = useState(true);

  let newtotal = user.total;
  let mutee = false;
  // const [newtotal, setNewtotal] = useState(user.total);
  const [playerb, setPlayerb] = useState('Player 2');


  const Getran = () => {
    const dd = Math.ceil(Math.random() * 10);
    if (dd < 7) {
      return dd;
    } else {
      return Getran();
    }
  };

  const Played = async (e) => {
    e.preventDefault();
    // e.target.classList.remove('ddiv', 'dicex', 'cursor-pointer');
    // setBox(false);

    const aud = document.getElementById('aud');

    if (pactive) {
      // setBox(true);
      let aa = Getran();
      let bb = Getran();
      setDicea(aa);
      setDiceb(bb);

      // if(aa!==5){
      const datt = new Date();
      const hours = datt.getHours().toString().padStart(2, '0');
      const minutes = datt.getMinutes().toString().padStart(2, '0');
      const seconds = datt.getSeconds().toString().padStart(2, '0');
      const timeString = `${hours}:${minutes}:${seconds}`;
      

      const newg = { ...user, total: newtotal, dtotal: money(newtotal) };
      const formDataToSend = new FormData();
      formDataToSend.append('newtotal', newtotal);
      formDataToSend.append('userid', user.userid);
      formDataToSend.append('gameid', games[0].gameid);
      formDataToSend.append('aa', aa);
      formDataToSend.append('newstake', newstake);

      console.log(formDataToSend.newtotal + ' are  fe tokens');

      try {
        await axios.post(`${cont.api}stake`, formDataToSend).then((res) => {
          if (res.data.success) {
            // setBox(false);
            e.target.classList.remove('ddiv', 'dicex', 'cursor-pointer');

            if (res.data.user) {
              const kuser = res.data.user;

              // const aar = aa
              let aar = Number(res.data.newaa);

              if (aar === 1) {
                // alert(aa + " is aa o")
                setPimage(done);

                setDisplay('Oops ! -' + money(newstake));
              } else if (aar === 2) {
                // alert(aa)
                setPimage(dtwo);

                setDisplay('Oops ! -' + money(newstake));
              } else if (aar === 3) {
                setPimage(dthree);

                setDisplay('Oops ! -' + money(newstake));
              } else if (aar === 4) {
                setDisplay('Narrow escape +' + money(res.data.profit));

                setPimage(dfour);
              } else if (aar === 5) {
                setPimage(dfive);

                setDisplay('splendid ! +' + money(res.data.profit));
              } else if (aar === 6) {
                setPimage(dsix);

                setDisplay('Excellent ! +' + money(res.data.profit));

                openModal();
                aud.currentTime = 150;
              }
              newtotal = kuser.total;
              // setNewstake(dnewstake)

              if (!(aa === 6)) {
                // setPactive(!pactive);
                aud.currentTime = 240;
              }
              // if (!(aa === (5 || 6))) {
              //   // setColor('lose');
              //   // aud.currentTime = 240;
              // }

              const ddd = res.data.user;
              setUser(ddd);

              setUser(ddd);
              newtotal = kuser.total;
              setUser((prev) => ({ ...prev, ...ddd }));
              // setData(() => [dat, ...data]);

              setPactive(false);
              setColor((color) => (aa !== 6 ? 'lose' : 'win'));
              setTimeout(function () {
                setPimage('/images/dice.gif');
                setPactive(true);
                e.target.classList.add('ddiv', 'dicex', 'cursor-pointer');
                setStake(true);
                setStatus('Stake mode');
              }, 6000);
              setTimeout(function () {
                // aud.pause()
                aud.currentTime = 0;
              }, 28000);
              // navigate('/');
            } else {
              // Dalert(res.data.message);
              Swal.fire({
                title: `Connection error`,
                text: `Check your connection`,
                icon: 'error',
              });
              setPactive(true);
              e.target.classList.add('ddiv', 'dicex', 'cursor-pointer');
              // setPimage('/images/dice.gif');
              setStake(true);
              setStatus('Stake mode');
            }
          } else {
            // Dalert('pls check your network connection');
            Swal.fire({
              title: `error :${res.data.message}`,
              text: `${res.data.message}`,
              icon: 'error',
            });
            setPactive(true);
            e.target.classList.add('ddiv', 'dicex', 'cursor-pointer');
            setPimage('/images/dice.gif');
            setStake(true);
            setStatus('Stake mode');
          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
        Swal.fire({
          title: `Connection error`,
          text: `Check your connection`,
          icon: 'error',
        });
        setPactive(true);
        e.target.classList.add('ddiv', 'dicex', 'cursor-pointer');
        setPimage('/images/dice.gif');
        setStake(true);
        setStatus('Stake mode');
      }

      // }
      // else{

      //   setTimeout(function () {
      //     setPactive(true);
      //     e.target.classList.add('ddiv', 'dicex', 'cursor-pointer');
      //     setPimage('/images/dice.gif');
      //     setColor("gfghj")
      //     setStatus('Stake mode');
      //   }, 6000);
      //   setTimeout(function () {
      //     // aud.pause()
      //     aud.currentTime = 0;
      //   }, 28000);
      // }
    }
  };

  const [dnewstake, setDewstake] = useState(money(0));

  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };
  const Getval = (e) => {
    e.preventDefault();
    setNewstake(e.target.value);
    setDewstake(money(e.target.value));
    // setDisplay('Stake: ' + money(newstake));
  };
  const Staken = (e) => {
    const aud = document.getElementById('aud');

    if (newstake > 99.99) {
      if (newtotal >= newstake) {
        // alert(newstake);
        setStake(false);
        setNewstake(newstake);
        setDisplay('Staked :' + money(newstake));
        setStatus('Live mode');
        setColor('gfghj');

        aud.currentTime = 0;
        aud.play();
      } else {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success mx-auto',
            cancelButton: 'btn btn-danger mx-auto',
          },
          buttonsStyling: true,
        });
        swalWithBootstrapButtons
          .fire({
            title: `Your account balance is too low for this ${dnewstake} stake`,
            text: `Pls make deposit `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Make deposit!',
            cancelButtonText: 'No, not now !',
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // swalWithBootstrapButtons.fire({
              //   title: 'Deleted!',
              //   text: 'Your file has been deleted.',
              //   icon: 'success',
              // });
              // alert('j')
              setDeposit(true);
              setStatus('Account Deposit ');
            }
          });
      }
    } else {
      Swal.fire({
        title: `pls enter a valid amount`,
        text: `You can not stake less than NGN 100.00`,
        icon: 'warning',
      });
    }
  };

  const Pay = async (e) => {
    e.preventDefault();
    if (pp) {
      if (Number(newdep) > 99) {
        setPp(false);
        try {
          const formDataToSend = new FormData();

          formDataToSend.append('userid', user.userid);
          formDataToSend.append('gameid', games[0].gameid);
          formDataToSend.append('amount', newdep);
          formDataToSend.append('api', cont.api);
          formDataToSend.append('fe', cont.fe);

          await axios
            .post(`${cont.api}newdeposit`, formDataToSend)
            .then((res) => {
              if (res.data.success) {
                window.location.href = res.data.url;
              } else {
                // Dalert('pls check your network connection');
                Swal.fire({
                  title: `error :We are unable to proceed with your payment`,
                  text: `try again later`,
                  icon: 'error',
                });
              }
            });
        } catch (error) {
          // Handle error if needed
          console.error('Error:', error);
          Swal.fire({
            title: `Connection error`,
            text: `Check your connection`,
            icon: 'error',
          });
          setPp(true);
        }
      } else {
        Swal.fire({
          title: `Sorry you cant deposit less than ₦100.00`,
          text: `Deposit too low`,
          icon: 'error',
        });
      }
    }
  };
  const elementRef = useRef(null);

  useEffect(() => {
    const Check = async () => {
      try {
        await axios.post(`${cont.api}ifuser`, { jwtt }).then((res) => {
          if (res.data.success) {
            if (res.data.user) {
              setUser(res.data.user);
              setFirst(true);

              navigate('/single');
            } else {
              Signout();
            }
          } else {
            Signout();
          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
      }
    };
    if (jwtt && !user) {
      Check();
    } else if (!user) {
      navigate('/');
    }
  }, [navigate, jwtt, user]);

  return (
    <div>
      <div>
        <Mheader
          user={user}
          setUser={setUser}
          cont={cont}
          games={false}
          className=""
        />

        {user ? (
          <div className="main-bannerc mb-0 ">
            <div class="sectionj trending ">
              <div class="container" style={{minHeight:"90vh"}}>
                <div class="row justify-content-between">
                  <div class="col-lg-6 mb-0">
                    <div class="section-heading mb-0">
                      <h2 className="text-light tida">{game.name}</h2>
                      <audio
                        muted={mute}
                        id="aud"
                        src="/audio/action.mp3"
                      ></audio>

                      <div className="d-flex justify-content-between  text-light">
                        <div className="get text-light tida">
                          1 x {game.one}
                        </div>
                        <div className="get text-light tida">
                          2 x {game.two}
                        </div>
                        <div className="get text-light tida">
                          3 x {game.three}
                        </div>
                        <div className="get text-light tida">
                          4 x {game.four}
                        </div>
                        <div className="get text-light tida">
                          5 x {game.five}
                        </div>
                        <div className="get text-light tida">
                          6 x {game.six}
                        </div>
                        <div className="get text-light tida">
                          {!mute ? (
                            <FontAwesomeIcon
                              icon={faVolumeMute}
                              className="cursor-pointer"
                              onClick={(e) => setMute(!mute)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faPlay}
                              className="cursor-pointer"
                              onClick={(e) => setMute(!mute)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showModal && (
                    <div className="modalf position-absolute z-3">
                      <div className="modal-content position-absolute  text-center">
                        <h2
                          className="text-light"
                          style={{ textShadow: '2px 4px 4px black' }}
                        >
                          Congratulations!
                        </h2>
                        <img
                          className="ml-5 tida"
                          style={{
                            objectFit: 'contain',
                            width: '100px',
                            borderRadius: '50%',
                          }}
                          src="/images/cong.gif"
                          alt="You won"
                        />
                        <p className="text-light text-center tida">
                          Wow ! you just won &nbsp;
                          <span
                            className=""
                            style={{
                              fontSize: '25px',
                              fontWeight: '900',
                              color: 'gold',
                              textShadow: '2px 4px 4px green',
                            }}
                          >
                            {money(newstake * 2)} &nbsp;
                          </span>
                          !!
                        </p>
                      </div>
                    </div>
                  )}
                  <div class="col-lg-6">
                    <div class="main-button">
                      <Link
                        to="#jk"
                        className={[
                          stake
                            ? 'bg-warning tida'
                            : 'tida bg-success blinking-text',
                        ]}
                      >
                        {status}
                      </Link>
                    </div>
                  </div>
                  <div className="text-center ">
                    <h2 className="text-warning text-center rod">
                      {display}
                      {/* Stake :{money(newstake)} */}
                    </h2>
                  </div>
                  <div className="container justify-content-evenly">
                    <div className="bara movekb row mb-5">
                      <div
                        className="baby whiteb col-2 rounded tida text-center"
                        style={{ boxShadow: '2px 6px 3px black' }}
                      >
                        <h5>Life</h5>
                      </div>
                      <div
                        className="baby blackb col-2 text-center"
                        style={{ boxShadow: '2px 6px 3px black' }}
                      >
                        Is
                      </div>
                      <div
                        className="baby whiteb col-2 text-center"
                        style={{ boxShadow: '2px 6px 3px black' }}
                      >
                        A
                      </div>
                      <div
                        className="baby blackb col-2  text-center tida"
                        style={{ boxShadow: '2px 6px 3px black' }}
                      >
                        <h5>Game</h5>
                      </div>
                      <div
                        className="baby whiteb col-2 text-center"
                        style={{ boxShadow: '2px 6px 3px black' }}
                      >
                        Of
                      </div>
                      <div
                        className="baby blackb col-2 rounded text-center"
                        style={{ boxShadow: '2px 6px 3px black' }}
                      >
                        Picks
                      </div>
                    </div>

                    {stake ? (
                      deposit ? (
                        <div className="row">
                          <div
                            className="row align-items-center text-center"
                            style={{
                              // backgroundImage: 'url(/images/gametray.gif)',
                              height: '30vh',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                            }}
                          >
                            <div className="text-center col-8 mx-auto ">
                              <div className="d-flex align-items-center justify-content-between ">
                                <img
                                  src="/images/dice.gif"
                                  className="d-none d-md-block text-center cursor-pointer"
                                  style={{
                                    height: '30vh',
                                    width: '50vw',
                                    textAlign: 'center',
                                  }}
                                  alt=""
                                />
                                <img
                                  src="/images/dice.gif"
                                  className="d-md-none d-block text-center cursor-pointer"
                                  style={{
                                    height: '15vh',
                                    width: '15vw',
                                    textAlign: 'center',
                                  }}
                                  alt=""
                                />
                                <div className="">
                                  {pp ? (
                                    <div>
                                      <h2 className="text-light">
                                        Enter amount
                                      </h2>
                                      <input
                                        type="number"
                                        name=""
                                        id=""
                                        value={newdep}
                                        className="text-center border-0 rounded"
                                        style={{ outline: 'none' }}
                                        onChange={(e) => setDep(e.target.value)}
                                      />
                                    </div>
                                  ) : (
                                    <h2 className="text-light tida jbtw">
                                      <span className='blinking-text'>on it ...</span> &nbsp;{' '}
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                      />
                                    </h2>
                                  )}
                                </div>
                                <div className=" ">
                                  {pp ? (
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id={`tooltip`}>
                                          You are about to pay {money(newdep)}
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        onClick={Pay}
                                        className="text-center  clkbtn text-light tida"
                                      >
                                        Proceed
                                      </button>
                                    </OverlayTrigger>
                                  ) : (
                                    <div>
                                      <br />
                                      <span>
                                        <small className="text-light">
                                          no risk no reward !
                                        </small>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div
                            className="row align-items-center text-center"
                            style={{
                              // backgroundImage: 'url(/images/gametray.gif)',
                              height: '30vh',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                            }}
                          >
                            <div className="text-center col-8 mx-auto ">
                              <div className="d-flex align-items-center justify-content-between ">
                                <div className="d-none d-md-block">
                                  {/* <img
                                    src="/images/dice.gif"
                                    className="text-center cursor-pointer"
                                    style={{
                                      height: '30vh',
                                      width: '150px',
                                      textAlign: 'center',
                                    }}
                                    alt=""
                                  /> */}
                                  <img
                                    src="/images/walkb.gif"
                                    className="text-center cursor-pointer"
                                    style={{
                                      height: '30vh',
                                      width: '150px',
                                      textAlign: 'center',
                                    }}
                                    alt=""
                                  />
                                </div>
                                <div className="row align-items-center col-md-10">
                                  <div className="col-12 col-md-6 ">
                                    <h2 className="text-light tida">
                                      Stake amount
                                    </h2>
                                    <input
                                      type="number"
                                      name=""
                                      autoFocus
                                      id=""
                                      value={newstake}
                                      style={{ outline: 'none' }}
                                      className="text-center border-0  rounded"
                                      onChange={Getval}
                                    />
                                    <div className="d-flex justify-content-evenly text-light">
                                      <p
                                        className="text-light cursor-pointer tida"
                                        onClick={() =>
                                          setNewstake(Number(newstake) + 100)
                                        }
                                      >
                                        +100
                                      </p>
                                      <p
                                        className="text-light cursor-pointer tida"
                                        onClick={() =>
                                          setNewstake(Number(newstake) + 500)
                                        }
                                      >
                                        +500
                                      </p>
                                      <p
                                        className="text-light cursor-pointer tida"
                                        onClick={() =>
                                          setNewstake(Number(newstake) + 1000)
                                        }
                                      >
                                        +1000
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 mt-4 mt-md-0 mx-auto text-center ">
                                    &nbsp;
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id={`tooltip`}>
                                          Stake {money(newstake)}
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <button
                                          onClick={Staken}
                                          className="text-center clkbtn text-light tida px-2"
                                        >
                                          Stake
                                        </button>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="row move">
                        <div className="row mt-n5 align-items-center gdjiv">
                          <div className="text-center col-4 px-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <img
                                src="/images/walkb.gif"
                                className="d-none d-md-block text-center"
                                style={{
                                  height: '30vh',
                                  width: '170px',
                                  textAlign: 'center',
                                }}
                                alt=""
                              />
                              <img
                                onClick={Played}
                                src={pimage}
                                className="d-none d-md-block text-center ddiv dicex cursor-pointer"
                                style={{
                                  height: '25vh',
                                  width: '150px',
                                  textAlign: 'center',
                                }}
                                alt=""
                              />
                            </div>
                            <div className="d-flex col-12 justify-content-between align-items-center">
                              <img
                                src="/images/walkb.gif"
                                className="d-block col-6 d-md-none text-center "
                                style={{
                                  height: '17vh',
                                  width: '28vw',
                                  textAlign: 'center',
                                }}
                                // style={{ maxWidth: '100%', height: 'auto' }}
                                alt=""
                              />

                              <img
                                onClick={Played}
                                src={pimage}
                                className="d-block col-6 d-md-none text-center ddiv dicex cursor-pointer"
                                style={{
                                  height: '10vh',
                                  width: '20vw',
                                  textAlign: 'center',
                                }}
                                // style={{ maxWidth: '100%', height: 'auto' }}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row  d-flex ">
                      <img
                        src="/images/fireb.gif"
                        className="col-2 movex"
                        alt=""
                        style={{
                          height: '30px',
                          objectFit: 'cover',
                          marginTop: '-20px',
                        }}
                      />
                      <img
                        src="/images/fire.gif"
                        className="col-2 movex"
                        alt=""
                        style={{
                          height: '30px',
                          objectFit: 'cover',
                          marginTop: '-20px',
                        }}
                      />
                      <img
                        src="/images/fireb.gif"
                        className="col-2 movex"
                        alt=""
                        style={{
                          height: '30px',
                          objectFit: 'cover',
                          marginTop: '-20px',
                        }}
                      />
                      <img
                        src="/images/fire.gif"
                        className="col-2 movex"
                        alt=""
                        style={{
                          height: '30px',
                          objectFit: 'cover',
                          marginTop: '-20px',
                        }}
                      />
                      <img
                        src="/images/fireb.gif"
                        className="col-2 movex"
                        alt=""
                        style={{
                          height: '30px',
                          objectFit: 'cover',
                          marginTop: '-20px',
                        }}
                      />
                      <img
                        src="/images/fire.gif"
                        className="col-2 movex"
                        alt=""
                        style={{
                          height: '30px',
                          objectFit: 'cover',
                          marginTop: '-20px',
                        }}
                      />
                    </div>
                    <div className="barb moveb ml-2 row">
                      <div
                        className="baby black col-2 rounded"
                        style={{ boxShadow: '2px -6px 3px black' }}
                      >
                        Now
                      </div>
                      <div
                        className="baby white col-2"
                        style={{ boxShadow: '2px -6px 3px black' }}
                      >
                        Its
                      </div>
                      <div
                        className="baby black col-2"
                        style={{ boxShadow: '2px -6px 3px black' }}
                      >
                        your
                      </div>
                      <div
                        className="baby white col-2"
                        style={{ boxShadow: '2px -6px 3px black' }}
                      >
                        turn
                      </div>
                      <div
                        className="baby black col-2"
                        style={{ boxShadow: '2px -6px 3px black' }}
                      >
                        to
                      </div>
                      <div
                        className="baby white col-2 rounded "
                        style={{ boxShadow: '2px -6px 3px black' }}
                      >
                        pick6
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <Footer/>
    </div>
  );
};

export default Single;
