import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Cookies from 'js-cookie';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import {Divider, Tooltip} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Menu from "@mui/material/Menu";

import AdbIcon from '@mui/icons-material/Adb';
import { Link, useNavigate } from 'react-router-dom';
import { useSpring } from 'react-spring';
import { FaCog, FaSignOutAlt } from 'react-icons/fa';
import {
  faDice,
  faGamepad,
  faUser,
  faExchangeAlt,
  faHome,
  faUserShield,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FaAngleDoubleRight } from 'react-icons/fa';
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = ({ user,data, setUser,db, back,trans,players,cont}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const tooltip = <Tooltip id="tooltip">Tooltip text</Tooltip>;

  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const pages = [
    { names: 'Db', links: '/admin', cond: db ? true : false },
    { names: 'Transactions', links: '/atrans', cond: trans ? true : false },
    {
      names: 'Players',
      links: '/players',
      cond: players ? true : false,
    }
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  

  return (
    <AppBar position="sticky">
      <CssBaseline />
      <Container maxWidth="xl" className="bg-primary">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page, index) => (
              <MenuItem key={index}>
                <Link
                  to={page.links}
                  textAlign="center"
                  className={page.cond ? 'active activeb' : ''}
                >
                  {page.names}
                </Link>
              </MenuItem>
            ))}
          </Menu>

          <img
            src="/images/emb.png"
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
            style={{ height: '60px', width: '20vw', objectFit: 'contain' }}
            className=""
            alt=""
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.7rem',
              color: 'inherit',
              textDecoration: 'none',
              fontSize: '30px',
              textShadow: '2px 2px 2px black',
            }}
          >
            <Link to="/" className="text-light">
              Emperor6
            </Link>
          </Typography>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              textShadow: '2px 2px 2px black',
            }}
          >
            Emperor6
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((el) => (
              <Button
                to={el.links}
                key={el.names} // Assuming `names` is unique for each el
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Link
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  to={el.links}
                  className={el.cond ? 'active  tida text-light' : 'text-light'}
                >
                  {el.names}
                </Link>
                {/* Rendering the `names` property */}
              </Button>
            ))}
            {!user ? (
              <div
                style={{ height: 'fit-content' }}
                className="col-6 d-flex justify-content-end align-items-center p-0"
              >
                <Button sx={{ my: 2, color: 'white', display: 'block' }}>
                  <Tooltip
                    title="total profit"
                    className="text-light d-none d-md-block"
                  >
                    <Link
                      to="/atrans"
                      className=" btn btn-danger  text-light rounded "
                    >
                      {data.profit}
                    </Link>
                  </Tooltip>
                </Button>
              </div>
            ) : (
              ''
            )}
          </Box>

          {user ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip
                title={'Hi ' + user.name}
                className="text-light d-none d-md-block"
              >
                <IconButton
                  className="text-light"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  {user.name}&nbsp;
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Account</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Dashboard</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography textAlign="center">
                    <a href={`${cont.api}tr`} target="_blank" rel="noreferrer">
                      Reset users
                    </a>
                  </Typography>
                </MenuItem>
                <MenuItem onClick={Signout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            ''
          )}

          {user ? (
            <Typography
              onClick={handleOpenUserMenu}
              className="tida text-light bg-danger p-1 rounded ml-5 text-end"
            >
              {user.dtotal}
            </Typography>
          ) : (
            ''
          )}
          {/* <LoadingButton loading variant="outlined">
            <span>Submit</span>
          </LoadingButton> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
