import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';
import Mheader from './Mheader';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import Footer from './Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCog, FaSignOutAlt } from 'react-icons/fa';

import { IconButton } from '@mui/material';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Fixtures from './Fixtures'
import Cookies from 'js-cookie';
// import Cookies from 'universal-cookie';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FaAngleDoubleRight } from 'react-icons/fa';

const Home = ({ cont, user, setUser, games, settings }) => {
  function money(amount) {
    var moneyFormat = Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    const dr = '₦' + moneyFormat.split('NGN')[1];
    return dr;
  }
  const [openDialogId, setOpenDialogId] = useState(null);

  const handleClose = () => {
    setOpenDialogId(null);
  };

  const handleOpen = (id) => {
    setOpenDialogId(id);
  };
  const jwtt = Cookies.get('jwt');
  const [first, setFirst] = useState(false);
  const [vid, setVid] = useState(false);

  const navigate = useNavigate();
  const [things, setThings] = useState({});
  const elementRef = useRef(null);
  const Check = async () => {
    try {
      await axios.post(`${cont.api}ifuser`, { jwtt }).then((res) => {
        if (res.data.success) {
          // setFirst(false);

          if (res.data.user) {
            setFirst(false);

            setUser(res.data.user);
            setFirst(false);

            navigate('/');
          } else {
            Signout();
          }
        } else {
          Signout();
        }
      });
    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (jwtt && !user) {
      Check();
      setFirst(false);
    }
  }, [jwtt, Check, navigate, first]);

  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1000 }, // Duration of the animation in milliseconds
  });
  const propsb = useSpring({
    from: { opacity: 0, transform: 'translateX(-150px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 500 }, // Duration of the animation in milliseconds
  });
  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };

  return (
    <div>
      <Mheader
        user={user}
        setUser={setUser}
        cont={cont}
        games={true}
        className=""
      />

      {user ? (
        <div className="main-bannerb mb-0 bg-transparent text-center text-center">
          {/* <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3 text-center bg-transparent jcenter align-items-center"
          >
            <Tab
              eventKey="home"
              className=""
              title={
                <span className="custom-tab-title  ">Virtual Football</span>
              }
            >
              <Fixtures user={user} cont={cont} />
            </Tab>
            <Tab
              eventKey="profile"
              title={<span className="custom-tab-title">Specials</span>}
            >
              <div class="sectionjk trending ">
                <div class="container" style={{ height: "110vh" }}>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="section-heading">
                    
                        <h2 ref={elementRef} className="text-light tida">
                          Select Tournament
                        </h2>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      
                    </div>
                    <div
                      className="row justify-content-evenly mx-auto"
                      
                    >
                      {games.map((el) => (
                        <div class="col-lg-3 col-md-6">
                          <div class="item">
                            <div class="thumb text-center mx-auto">
                              <Link to={el.to}>
                                <img
                                  src={el.image}
                                  className="dimage"
                                  height="150px"
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div class="down-content">
                              <span class="category">Select tournament</span>
                              <h4 className="tidan">{el.name} </h4>
                              <span
                                onClick={() => handleOpen(el.gameid)}
                                className="jend "
                              >
                                
                                <IconButton className="text-warning border-danger">
                                  <FontAwesomeIcon
                                    className="text-warning"
                                    icon={faInfoCircle}
                                  />
                                </IconButton>
                              </span>
                              <Dialog
                                open={openDialogId === el.gameid}
                                onClose={handleClose}
                              >
                                <DialogTitle>{el.name}</DialogTitle>
                                <DialogContent>
                                  <p>{el.about}</p>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose}>Close</Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs> */}
          <div class="sectionjk trending ">
            <div class="container" style={{ height: "110vh" }}>
              <div class="row">
                <div class="col-lg-6">
                  <div class="section-heading">
                   
                    <h2 ref={elementRef} className="text-light tida">
                      Select Tournament
                    </h2>
                  </div>
                </div>
                <div class="col-lg-6">
                
                </div>
                <div
                  className="row justify-content-evenly mx-auto"
               
                >
                  {games.map((el) => (
                    <div class="col-lg-3 col-md-6">
                      <div class="item">
                        <div class="thumb text-center mx-auto">
                          <Link to={el.to}>
                            <img
                              src={el.image}
                              className="dimage"
                              height="150px"
                              alt=""
                            />
                          </Link>
                        </div>
                        <div class="down-content">
                          <span class="category">Select tournament</span>
                          <h4 className="tidan">{el.name} </h4>
                          <span
                            onClick={() => handleOpen(el.gameid)}
                            className="jend "
                          >
                           
                            <IconButton className="text-warning border-danger">
                              <FontAwesomeIcon
                                className="text-warning"
                                icon={faInfoCircle}
                              />
                            </IconButton>
                          </span>
                          <Dialog
                            open={openDialogId === el.gameid}
                            onClose={handleClose}
                          >
                            <DialogTitle>{el.name}</DialogTitle>
                            <DialogContent>
                              <p>{el.about}</p>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-banner mb-0 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="caption header-text">
                  <animated.div style={props}>
                    <TextLoop>
                      <h6>
                        Welcome to &nbsp;
                        <span style={{ textShadow: "2px 2px 5px black" }}>
                          {cont.gname}
                        </span>
                      </h6>
                    </TextLoop>
                  </animated.div>
                  <animated.div style={props} className=" mb-0 p-0">
                    <h5
                      className="text-light text-center blink mb-1"
                      style={{
                        textShadow: "2px 2px 6px black",
                        fontWeight: "400",
                        letterSpacing: "9px",
                      }}
                    >
                      life is a game
                    </h5>
                  </animated.div>
                  <div className="col-lg-4 offset-lg-2  d-md-none">
                    <div className="right-image">
                      <img src="/images/dice.gif" alt="" />
                    </div>
                  </div>
                  <h2 className="mb-1 tida">Be Brave !</h2>

                  <animated.div style={propsb}>
                    <div className="mx-auto text-md-end text-center mb-3">
                      <Link
                        to="/sign"
                        className="col-md-4 col-6 btn btn-danger  text-light rounded border-light tida"
                      >
                        Start playing <FaAngleDoubleRight />
                      </Link>
                    </div>
                  </animated.div>

                  <p>
                    {cont.gname} is an engaging and strategic gaming platform
                    that tests players' luck and decision-making skills as they
                    partake in different gaming tournaments. Emperor6 emerges as
                    one of the innovative subsidiary products from stable of
                    Mikp tech innovatins.
                  </p>
                  {/* <p>
                    {cont.gname} is an engaging and strategic gaming platform that tests
                    players' luck and decision-making skills as they partake in different gaming tournaments. In this game, players take turns
                    rolling a standard six-sided dice, aiming to achieve the
                    coveted result of rolling a six.
                  </p> */}
                  <div className="search-input" id="search-input">
                    <form id="search" action="#" className="mb-3">
                      <input
                        type="text"
                        placeholder="Enter email"
                        id="searchText"
                        name="searchKeyword"
                        onkeypress="handle"
                      />
                      <button type="button">Subscribe Newsletter</button>
                      {/* <p>or</p>
                        <p className="text-light my-3 btn btn-primary">Login</p> */}
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-2 d-none d-md-block">
                <div className="right-image">
                  <img src="/images/dice.gif" alt="" />
                  {/* <span className="price">$22</span>
                    <span className="offer">-40%</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!user ? (
        <div className="features mt-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <Link to="/sign">
                  <div className="item">
                    <div className="image">
                      <img
                        src="/assets/images/featured-01.png"
                        alt=""
                        style={{ maxWidth: "44px" }}
                      />
                    </div>
                    <h4>Easy Signup</h4>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/sign">
                  <div className="item">
                    <div className="image">
                      <img
                        src="assets/images/featured-02.png"
                        alt=""
                        style={{ maxWidth: "44px" }}
                      />
                    </div>
                    <h4>User Friendly</h4>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/sign">
                  <div className="item">
                    <div className="image">
                      <img
                        src="assets/images/featured-03.png"
                        alt=""
                        style={{ maxWidth: "44px" }}
                      />
                    </div>
                    <h4>Play and earn</h4>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/sign">
                  <div className="item">
                    <div className="image">
                      <img
                        src="assets/images/featured-04.png"
                        alt=""
                        style={{ maxWidth: "44px" }}
                      />
                    </div>
                    <h4>Easy Withdrawals</h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
};

export default Home;
