import React, { useEffect, useState, useRef } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Button from 'react-bootstrap/Button';


const columns = [`Virtual Football `,'Home', 'Draw', 'Away'];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export default function StickyHeadTable({cont,user}) {
  const [page, setPage] = React.useState(0);
  const [fixtures, setFixtures] = useState([]);
  const [st, setSt] = useState(true);
  const [vt, setVt] = useState({});

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const yourFunction = async () => {
      if (fixtures) {
        try {
          const response = await axios.get(
            `${cont.api}fixtures`
          );
          // alert(user.userid);
          const responseData = response.data;
          if (responseData.success ) {
            

            setFixtures(responseData.fixtures);
            const nvt = responseData.vt;
            setVt((prevVt) => ({
              ...prevVt,
              ...nvt,
            }));
            
            // alert('hi');

            
          } else {
            // Handle other conditions if needed
            alert('failed');
            // Getupdates();
          }
        } catch (err) {
          console.error(err);
          // Handle error if needed
        }
      }
    };
    const intervalId = setInterval(() => {
      // Call your function here
      yourFunction();
    }, 1000); // 30 seconds interval

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [user]); // Empty dependency array ensures this effect runs only once after the initial render
  const [mins, setMins] = useState('');

  useEffect(() => {
    if (fixtures) {
      const intervalId = setInterval(() => {
        const currentTime = new Date();
        let expirationTime = new Date(vt.started ?vt.endstring:vt.startstring);
        // alert(vt.startstring);
        

        const remainingTime = expirationTime - currentTime;

        // Check if the remaining time is greater than 0
        if (remainingTime > 0) {
          const minutes = Math.floor(remainingTime / (1000 * 60));
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
          setMins(
            !vt.started
              ? `starts in ${minutes}m ${seconds}s`
              : `ends in ${minutes}m ${seconds}s`
          );
        } else {
            setMins(' - - - ');
            
          // Stop the interval when the timer expires
        //   clearInterval(intervalId);
        //   if(vt.started){
        //     setMins('Match Starts !');
        //     setSt(!st);
        //   }
        //   else if(vt.inplay){
        //     setSt(!st);

        //     setMins('Match Ends !');
        //   }
          
        }
      }, 500);

      // Clean up function to clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [fixtures,vt]);
  
  return (
    <div className="px-2 w-100 ">
      {vt.started ? (
        <Paper className="resp" sx={{ overflow: 'hidden' }}>
          <TableContainer
            className="bg-primary text-center"
            sx={{ maxHeight: 440 }}
          >
            <Table
              className="bg-primary"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className="bg-primary">
                <TableRow className="bg-primary">
                  
                    <TableCell
                      className="bg-primary text-light tida"
                      
                    >
                      
                        <div className="jbtw">
                          <h3 className='blinking-text'>Live games</h3>
                          <div className="  btn btn-danger blinkinhg-text text-light rounded rounded tida">
                            {mins}
                          </div>
                        </div>
                      
                     
                    </TableCell>
                    <TableCell
                      className="bg-primary text-light tida"
                      
                    >
                      
                        Home
                      
                     
                    </TableCell>
                    <TableCell
                      className="bg-primary text-light tida"
                      
                    >
                      
                        Away
                      
                     
                    </TableCell>
                 
                </TableRow>
              </TableHead>
              <TableBody className="bg-primary">
                {fixtures.map((el, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell className="tida text-light fw-bold">
                        <img
                          src={el.homelogo}
                          style={{ object: 'contain', width: '20px' }}
                          alt=""
                        />
                        &nbsp;
                        {el.home}
                        <br />
                        <img
                          src={el.awaylogo}
                          style={{ object: 'contain', width: '20px' }}
                          alt=""
                        />
                        &nbsp;
                        {el.away}
                      </TableCell>
                      <TableCell>
                        <Button className="t5" variant="light">
                          {el.homescore}
                        </Button>
                      </TableCell>
                      <TableCell className="">
                        <Button variant="light"> {el.awayscore}</Button>{' '}
                      </TableCell>
                      
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper className="resp" sx={{ overflow: 'hidden' }}>
          <TableContainer
            className="bg-primary text-center"
            sx={{ maxHeight: 440 }}
          >
            <Table
              className="bg-primary"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className="bg-primary">
                <TableRow className="bg-primary">
                  {columns.map((column, index) => (
                    <TableCell
                      className="bg-primary text-light tida"
                      key={index}
                    >
                      {index === 0 ? (
                        <div className="jbtw">
                          <h3>{columns[0]}</h3>
                          <div className="  btn btn-danger blinkinhg-text text-light rounded rounded tida">
                            {mins}
                          </div>
                        </div>
                      ) : (
                        column
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="bg-primary">
                {fixtures.map((el, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell className="tida text-light fw-bold">
                        <img
                          src={el.homelogo}
                          style={{ object: 'contain', width: '20px' }}
                          alt=""
                        />
                        &nbsp;
                        {el.home}
                        <br />
                        <img
                          src={el.awaylogo}
                          style={{ object: 'contain', width: '20px' }}
                          alt=""
                        />
                        &nbsp;
                        {el.away}
                      </TableCell>
                      <TableCell>
                        <Button className="t5" variant="light">
                          H
                        </Button>
                      </TableCell>
                      <TableCell className="text-warning">
                        <Button variant="warning">D</Button>{' '}
                      </TableCell>
                      <TableCell>
                        <Button variant="light">A</Button>{' '}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </div>
  );
}
