import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';

import Countdown from 'react-cntdwn';
import Footer from './Footer';

import Mheader from './Mheader';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import Swal from 'sweetalert2';

import { Link, useNavigate } from 'react-router-dom';
import { FaCog, FaSignOutAlt, FaMinus, FaPlus } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { TERipple } from 'tw-elements-react';
import axios from 'axios';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { OverlayTrigger, Tooltip ,Card} from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  faDice,
  faGamepad,
  faUser,
  faVolumeMute,
  faInfoCircle,
  faPlay,
  faAngleDoubleDown,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as Buttonr } from 'react-bootstrap';
import CssBaseline from '@mui/material/CssBaseline';

import Table from 'react-bootstrap/Table';

// import '@fortawesome/fontawesome-free/css/all.min.css';
function money(amount) {
  var moneyFormat = Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  const dr = '₦' + moneyFormat.split('NGN')[1];
  return dr;
}
const Team = ({
  quest,
  setQuest,
  quests,
  setQuests,
  user,
  setUser,
  cont,
  games,
  settings,
}) => {
  
  const game = games[1];
  const [nroll ,setNroll] = useState(0)
  const [showModal, setShowModal] = useState(false);
  const [box, setBox] = useState(false);
  const [mute, setMute] = useState(false);
  const jwtt = Cookies.get('jwt');
  const openModal = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 16000); // Adjust the time (in milliseconds) according to your needs
  };
  const [pimage, setPimage] = useState('/images/dice.gif');
  const [status, setStatus] = useState('Stake mode');
  const [stake, setStake] = useState(500);
  
  const [first, setFirst] = useState(false);
  const [newstake, setNewstake] = useState(100);
  const [display, setDisplay] = useState('Staked ' + money(newstake));
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const[p1,setP1] = useState('')
  const[p2,setP2] = useState('')
  // const[mins,setMins] = useState('')
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dicea, setDicea] = useState(1);
  const [color, setColor] = useState('fg');
  const [diceb, setDiceb] = useState(1);
  const [onit, setOnit] = useState(false);
  let [pp, setPp] = useState(true);
  const done = '/images/oneee.png';
  const dtwo = '/images/twoo.jfif';
  const dthree = '/images/threes.png';
  const dfour = '/images/ffour.png';
  const dfive = '/images/dice55.jfif';
  const dsix = '/images/six.png';
  const [rolls,setRolls] = useState([])
  const [hide,setHide] = useState(true)

  const [pactive, setPactive] = useState(true)
  
  let newtotal = user.total;
  

  const [mins, setMins] = useState('');

  useEffect(() => {
    if(quest){
      const intervalId = setInterval(() => {
      const currentTime = new Date();
      const expirationTime = new Date(quest.toexpirej);

      const remainingTime = expirationTime - currentTime;

      // Check if the remaining time is greater than 0
      if (remainingTime > 0) {
        const minutes = Math.floor(remainingTime / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        setMins(`Expires in ${minutes}m ${seconds}s`);
      } else {
        // Stop the interval when the timer expires
        clearInterval(intervalId);
        setMins('Expired !');
        if(roll > 9){
          navigate('/recs');
        }
      }
    }, 1000);

      // Clean up function to clear the interval on component unmount
      return () => clearInterval(intervalId)
    }
  }, [quest]);
  
  
  const Getran = () => {
    
    const dd = Math.ceil(Math.random() * 10);
    if (dd < 7) {
      return dd;
    } else {
      return Getran();
    }
  };
  const [grabb, setGrabb] = useState(true);

  const Grabbo = async(e) => {
    e.preventDefault();
    if(grabb){
        
        const beep = document.getElementById('beep');
        mute ? beep.pause() : beep.play();

        setGrabb(false);
        const aa = Getran();
        const bb = Getran();
        
          if (aa === 1) {
            setP1(done);
          } else if (aa === 2) {
            setP1(dtwo);
          } else if (aa === 3) {
            setP1(dthree);
          } else if (aa === 4) {
            setP1(dfour);
          } else if (aa === 5) {
            setP1(dfive);
          } else if (aa === 6) {
            setP1(dsix);
          }
          if (bb === 1) {
            setP2(done);
          } else if (bb === 2) {
            setP2(dtwo);
          } else if (bb === 3) {
            setP2(dthree);
          } else if (bb === 4) {
            setP2(dfour);
          } else if (bb === 5) {
            setP2(dfive);
          } else if (bb === 6) {
            setP2(dsix);
          }
       
        setTimeout(() => {
          setGrabb(true);
        }, 6000);
        try {
          const response = await axios.get(`${cont.api}rolled?userid=${user.userid}&questid=${quest.questid}&rolled=${(aa + bb)}`);
          const responseData = response.data;
          if (responseData.success && responseData.user) {
            setUser(responseData.user);

            // setQuest(responseData.quest);
            
            setQuests(responseData.quests);
            setNroll(responseData.roll);
            setRolls(responseData.rolls);

            if (responseData.roll.nroll > 9) {
              setNroll(0);

              // setHide(false);
              setTimeout(()=>{
                Swal.fire({
                  title: `Quest Completed !`,
                  text: `Check Quest history for quest progress and results`,
                  icon: 'success',
                });

                navigate(`/recs/:${quest.questid}`);
              },4000)
              // setNroll(0)
            }
            else{
              // setHide(false)
            }
            // alert(responseData.quests);
            // navigate('/double');
          } else {
            // Handle other conditions if needed
          }
        } catch (err) {
          console.error(err);
          // Handle error if needed
        }
    }
  };
  // completed
  const [exp,setExp] = useState()
  
  const Delquest = async(e)=>{
    e.preventDefault();
    await axios
            .get(`${cont.api}delquest?questid=${quest.questid}&userid=${user.userid}`)
            .then((res) => {
              if (res.data.success) {
                // setBox(false);
                
                if (res.data.user) {
                  const kuser = res.data.user;

                  setUser(kuser);

                  setRolls(res.data.rolls);

                  const ddd = res.data.quest;
                  setQuest([]);
                  setQuests(res.data.quests);

                  
                  // navigate('/');
                } else {
                  // Dalert(res.data.message);
                  Swal.fire({
                    title: `Connection error`,
                    text: `Check your connection`,
                    icon: 'error',
                  });
                }
              } else {

                setQuest(res.data.quest)
                setUser(res.data.user)
                
                // Dalert('. check your network connection');
                Swal.fire({
                  title: `error :This game has already been initialised `,
                  text: `You can not delete a quest in play`,
                  icon: 'error',
                });
              }
            });
  }

  const Createq = async () => {
    
    // e.target.classList.remove('ddiv', 'dicex', 'cursor-pointer');
    // setBox(false);

    const aud = document.getElementById('aud');

    if (!onit) {
      setOnit(true);
      // setBox(true);
      let aa = Getran();
      if (stake > user.total) {
        

        Swal.fire({
          title: `Account balance is too low for proposed Quest amount ${money(
            stake
          )}`,
          text: `Update(deposit) your account or stake lesser amount `,
          icon: 'error',
        });
      } else {
        // const newg = { ...user, total: newtotal, dtotal: money(newtotal) };
        const formDataToSend = new FormData();
        formDataToSend.append('stake', stake);
        formDataToSend.append('userid', user.userid);
        formDataToSend.append('gameid', games[0].gameid);
        formDataToSend.append('players', clients);
        formDataToSend.append('newstake', newstake);

        console.log(formDataToSend.newtotal + ' are  fe tokens');

        try {
          await axios
            .post(`${cont.api}createquest`, formDataToSend)
            .then((res) => {
              if (res.data.success) {
                // setBox(false);
                
                if (res.data.user) {
                  const kuser = res.data.user;

                  setUser(kuser);

                  

                  const ddd = res.data.quest;
                  setQuest(ddd);

                  
                  // navigate('/');
                } else {
                  // Dalert(res.data.message);
                  Swal.fire({
                    title: `Connection error`,
                    text: `Check your connection`,
                    icon: 'error',
                  });
                }
              } else {
                // Dalert('. check your network connection');
                Swal.fire({
                  title: `error :${res.data.message}`,
                  text: `${res.data.message}`,
                  icon: 'error',
                });
              }
            });
        } catch (error) {
          // Handle error if needed
          console.error('Error:', error);
          Swal.fire({
            title: `Connection error`,
            text: `Check your connection`,
            icon: 'error',
          });
        }
      }
      setOnit(false);
      handleClose();
      handleClosee();
    }
    setOnit(false);
  };

  const [vala, setVala] = useState(1000);
  const [clients, setClients] = useState(2);

  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };
  
  const elementRef = useRef(null);

    useEffect(() => {
      const Check = async () => {
        try {
          await axios.post(`${cont.api}ifuser`, { jwtt }).then((res) => {
            if (res.data.success) {
              if (res.data.user) {
                setUser(res.data.user);
                setFirst(true);

                navigate('/double');
              } else {
                Signout();
              }
            } else {
              Signout();
            }
          });
        } catch (error) {
          // Handle error if needed
          console.error('Error:', error);
        }
      };
      if (jwtt && !user) {
        Check();
      } else if (!user) {
        navigate('/');
      }
    }, [navigate, jwtt, user]);
  const [navigationCount, setNavigationCount] = useState(0);

  

  const roll = '/images/dolo.gif';
  // const roll ='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcW5hdDh1Z2R2eGV0b3ZicHRtZ3Nxc3p6M2YzY3c0aGkzdTJtcTR6ZSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oGRFlpAW4sIHA02NW/giphy.gif';
  const [openDialogId, setOpenDialogId] = useState(null);

  const handleClosee = () => {
    setOpenDialogId(null);
    handleClose();
  };
  const Inclient = (e) => {
    if (clients < 10) {
      setClients(clients + 1);
    } else {
      handleClosee();
      handleClose();

      Swal.fire({
        title: `Proposed Quest members number is too high `,
        text: `Quest members can not be more than 10 `,
        icon: 'warning',
      });
    }
  };
  const Inclientm = (e) => {
    if (clients > 2) {
      setClients(clients - 1);
    } else {
      handleClosee();
      handleClose();

      Swal.fire({
        title: `Proposed Quest members number is too low `,
        text: `Quest members can not be less than 2 .`,
        icon: 'warning',
      });
    }
  };
  const Cp = (e) => {
    setStake(stake + vala);
  };
  const Cm = (e) => {
    const istake = stake - vala;
    if (istake >= 500) {
      setStake(stake - vala);
    } else {
      handleClose();
      handleClosee();
      Swal.fire({
        title: `Proposed stake amount is too low `,
        text: `Quest stake can not be less than 500.00 .`,
        icon: 'warning',
      });
    }
  };
  const Getupdates = async () => {
    try {
      const response = await axios.get(`${cont.api}questupdate/${user.userid}`);
      const responseData = response.data;
      if (responseData.success) {
        setUser(responseData.user);

        setQuest(responseData.quest);
        
        setQuests(responseData.quests);
        setRolls(responseData.rolls);
        setNroll(responseData.roll);
        // alert(responseData.quests);
        navigate('/double');
      } else {
        // Handle other conditions if needed
      }
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
    if (quest) {
      const expiryTime = new Date(quest.toexpirej); // Set the expiry time here
      expiryTime.setMinutes(expiryTime.getMinutes() + 10);
      setExp(expiryTime); // Example: 10 minutes from now
    }
  };
 
  
  let [jj,setJj] =useState(true)
  useEffect(()=>{
    if (user && jj) {
      setJj(false);
        Getupdates();

    //   setInterval(()=>{
    //     Getupdates();
    //   },10000)
    }
  },[user])
  const [choose,setChoose] = useState(false)
  const Getquests = async(e)=>{
    e.preventDefault()
    
    try { 
          await axios 
            
            .get(`${cont.api}quests/${user.userid}`)
            .then((res) => {
              if (res.data.success) {
                
                if (res.data.user) {
                  setQuests(res.data.quests)
                  const qqq = res.data.quests

                  const ddd = res.data.user;
                  setUser(ddd);
                  if(qqq.length >0){
                    handleOpentt('quests');
                  }


                  
                } else {
                
                  Swal.fire({
                    title: `Connection error`,
                    text: `Check your connection`,
                    icon: 'error',
                  });
                }
              } else {
                // Dalert('. check your network connection');
                Swal.fire({
                  title: `error :${res.data.message}`,
                  text: `${res.data.message}`,
                  icon: 'error',
                });
              }
            });
        } catch (error) {
          // Handle error if needed
          console.error('Error:', error);
          Swal.fire({
            title: `Connection error`,
            text: `Check your connection`,
            icon: 'error',
          });
        }
  }
  const cols = [
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
  ];
  const handleOpentt = (id) => {
    setOpenDialogId(id);
  };
  const Joinq = async(qid,qh)=>{
    
    if (user.total >= qh) {

      
      try {
        const response = await axios.get(
          `${cont.api}joinquest?&qid=${qid}&uid=${user.userid}`
        );
        const responseData = response.data;
        if (responseData.success && responseData.user) {
          setUser(responseData.user);

          setQuest(responseData.quest);
          if (!responseData.quest) {
            Swal.fire({
              title: `Oops sorry this quest is no longer available`,
              text: `Pls select another quest .`,
              icon: 'warning',
            });
          }

          setQuests(responseData.quests);
          // alert(responseData.quests);
          navigate('/double');
        } else {
          // Handle other conditions if needed
        }
      } catch (err) {
        console.error(err);
        // Handle error if needed
      }
      handleClosee();
    } else {
      handleClosee();
      Swal.fire({
        title: `Your account balance is too low for this quest  `,
        text: `Pls fund your account and try again.`,
        icon: 'warning',
      });
    }
  }
  
  useEffect(() => {
    const yourFunction = async () => {
      if (quest) {
        try {
          const response = await axios.get(
            `${cont.api}getrolls?&qid=${quest.questid}&uid=${user.userid}`
          );
          // alert(user.userid);
          const responseData = response.data;
          if (responseData.success && responseData.user) {
            setUser(responseData.user);

            setQuest(responseData.quest);
                        // alert('hi');

            setQuests(responseData.quests);
            if(rolls.length > 0) {
              setRolls(responseData.rolls);
            }
            else{
              // setRolls(false);

            }
            // alert(responseData.quests);
            // navigate('/double');
          } else {
            // Handle other conditions if needed
            alert('failed');
            // Getupdates();
          }
        } catch (err) {
          console.error(err);
          // Handle error if needed
        }
      }
    };
    const intervalId = setInterval(() => {
      // Call your function here
      yourFunction();
    }, 5000); // 30 seconds interval

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [user]); // Empty dependency array ensures this effect runs only once after the initial render

  const colo = [
    'text-danger',
    'text-warning',
    'text-success',
    'text-info',
    'text-primary',
    'text-secondary',
  ];
  return (
    <div>
      <div>
        <CssBaseline />
        <audio mute={mute} hidden id="beep" src="/audio/roll.mp3"></audio>
        <Mheader
          user={user}
          setUser={setUser}
          cont={cont}
          games={false}
          className=""
        />

        {user ? (
          <div className="main-bannerc my-auto jalign">
            <div class="sectionj trending ">
              <div class="container">
                <div class="row justify-content-between ">
                  <div class="col-lg-12 mb-0" style={{ maxHeight: '120vh' }}>
                    <div class="section-heading mb-0">
                      <div className="caption header-text jbtw">
                        <div className="jbtw jalign align-items-center">
                          <h3 className="text-light tida">
                            {game.name} {'  '}
                          </h3>{' '}
                          &nbsp;
                          <div className="get text-light tida">
                            {!mute ? (
                              <FontAwesomeIcon
                                icon={faVolumeMute}
                                className="cursor-pointer"
                                onClick={(e) => setMute(!mute)}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faPlay}
                                className="cursor-pointer"
                                onClick={(e) => setMute(!mute)}
                              />
                            )}
                          </div>
                        </div>

                        {user.ingame ? (
                          <div>
                            {/* <h3>{quest.questid}</h3> */}
                            <div
                              onClick={() => handleOpentt('we')}
                              className=" col-12 btn btn-danger blinking-text text-light rounded rounded tida"
                            >
                              {mins}
                            </div>
                          </div>
                        ) : (
                          <div className="my-2 mx-1">
                            {/* <h3>{game.name}</h3> */}
                            <div
                              onClick={() => handleOpentt('12')}
                              className="col-md-12 col-12 btn btn-danger  text-light rounded rounded tida"
                            >
                              What is {game.name} ?
                            </div>
                          </div>
                        )}
                      </div>
                      <audio
                        muted={mute}
                        id="aud"
                        src="/audio/action.mp3"
                      ></audio>
                      <Dialog
                        open={openDialogId === '12'}
                        onClose={handleClosee}
                      >
                        <DialogTitle>What is {game.name} ?</DialogTitle>
                        <DialogContent>
                          <p>{game.about}</p>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClosee}>Close</Button>
                        </DialogActions>
                      </Dialog>
                      {user.ingame ? (
                        <Dialog
                          open={openDialogId === 'we'}
                          onClose={handleClosee}
                        >
                          <DialogTitle className="text-center">
                            {mins}{' '}
                          </DialogTitle>
                          <DialogContent className="text-center">
                            <p>Quest id :{quest.questid}</p>
                            <p>Created :{quest.questmadeon}</p>
                            <p>
                              <IconButton className="text-warning border-danger">
                                <FontAwesomeIcon
                                  className="text-warning"
                                  icon={faInfoCircle}
                                />
                              </IconButton>
                              Quest member with the highest point wins after all
                              Quest members have completed ten rolls.
                            </p>
                            <p>
                              Quest members are advised to complete their quest
                              before {quest.toexpire} as player with the highest
                              point on expiry wins .
                            </p>
                            <h2 className="blinking-text text-center">
                              BEST OF LUCK !
                            </h2>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClosee}>Close</Button>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ''
                      )}
                    </div>
                    {!user.ingame ? (
                      <Dialog
                        open={openDialogId === 'quests'}
                        onClose={handleClosee}
                      >
                        <DialogTitle className="text-center">
                          {!quests ? 'Quests' : `Quests (${quests.length})`}
                        </DialogTitle>
                        <DialogContent className="text-center row col-12">
                          <div className="container">
                            <div className="col-12">
                              {quests
                                ? quests.map((el, index) => (
                                    <Card
                                      bg={cols[index].toLowerCase()}
                                      key={el.questid}
                                      text={
                                        cols[index].toLowerCase() === 'light'
                                          ? 'dark'
                                          : 'white'
                                      }
                                      style={{ width: '18rem' }}
                                      className="mb-2 col-6"
                                    >
                                      <Card.Header>
                                        Quest by {el.adminame}
                                      </Card.Header>
                                      <Card.Body>
                                        <Card.Title>
                                          Stake {el.dquesthold}
                                        </Card.Title>
                                        <Card.Text>
                                          {/* <h6 className="text-light">
                                            you will play a maximum of ten
                                            (dice) rolls before the expiry time
                                            in competion with other quest
                                            members after which the highest
                                            point wins total stake
                                          </h6> */}
                                          {/* <small className="text">
                                            total stake - 10% total stake
                                          </small> */}
                                          <p className="text-light">
                                            created {el.questmadeon}
                                          </p>
                                          <p className="text-light">
                                            expiry date : {el.toexpiredate}
                                          </p>
                                          <small className="text-light">
                                            created by {el.adminame}
                                          </small>
                                        </Card.Text>
                                        <OverlayTrigger
                                          key="top"
                                          overlay={
                                            <Tooltip id="tooltip-top">
                                              Clicking the button below means
                                              you have accepted to stake $
                                              {el.dquesthold}
                                            </Tooltip>
                                          }
                                        >
                                          {/* <Button className="btn btn-success">
                                            Enter
                                          </Button> */}
                                          <Buttonr
                                            onClick={() =>
                                              Joinq(el.questid, el.questhold)
                                            }
                                            variant="success"
                                          >
                                            Enter Quest
                                          </Buttonr>
                                        </OverlayTrigger>
                                      </Card.Body>
                                    </Card>
                                  ))
                                : ''}
                            </div>
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClosee}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    ) : (
                      ''
                    )}
                    {user.ingame && quest.initt ? (
                      <div
                        style={{ borderRadius: '50%', height: '145vh' }}
                        className=" rounded align-items-center pb-4"
                      >
                        <div className="jevenly text-light">
                          <h6 className="text-light">
                            Questid :{quest.questid}
                          </h6>
                          <h6>Rolled {nroll.nroll}</h6>
                          <h4 className="blinking-text">
                            {' '}
                            {nroll.rolled} Points{' '}
                          </h4>
                        </div>
                        {/* <Link to={`/recs/:${quest.questid}`}>rec</Link> */}

                        <div className="  px-1">
                          {rolls && rolls.length > 0 ? (
                            <Table
                              striped
                              bordered
                              hover
                              size="sm"
                              className="py-3 text-center table overflow-auto rounded"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Grabbs</th>
                                  <th>Points</th>
                                  <th>Last grabb</th>
                                  {/* <th>Result</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {rolls.map((el, index) => (
                                  <tr
                                    key={index + 1}
                                    className={[
                                      el.userid === user.userid
                                        ? 'text-warning font-weight-bolder'
                                        : 'text-light',
                                      'text-center',
                                      colo[index],
                                    ]}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{el.name}</td>
                                    <td>{el.nroll}</td>
                                    <td>{el.rolled}</td>
                                    <td>{el.time}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            ''
                          )}
                        </div>

                        {hide ? (
                          <div
                            onClick={Grabbo}
                            className="rounded mb-4 py-2 bala align-items-center jcenter tanyad"
                          >
                            <div className="col-12 jevenly align-items-center">
                              {grabb ? (
                                <h1 className="grabb blinking-text">GRABB !</h1>
                              ) : (
                                <div className="jbtw col-12">
                                  <img
                                    src={p1}
                                    alt="not available"
                                    style={{
                                      height: '25vh',
                                      width: '150px',
                                      textAlign: 'center',
                                    }}
                                    className="blinking-text mx-auto"
                                  />

                                  <img
                                    style={{
                                      height: '25vh',
                                      width: '150px',
                                      textAlign: 'center',
                                    }}
                                    className="blinking-text mx-auto"
                                    src={p2}
                                    alt="not available"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <h2 className="text-center blinking-text text-light">
                            pls wait for other players to complete their quest
                          </h2>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{ borderRadius: '50%', height: '145vh' }}
                        className=" rounded align-items-center "
                      >
                        <div style={{ height: '55vh' }}>
                          {quest ? (
                            quest.initt ? (
                              <div
                                onClick={Grabbo}
                                className="rounded py-2 col-12 bala align-items-center jcenter tanyad"
                              >
                                <div className="col-12 jevenly align-items-center">
                                  {grabb ? (
                                    <h1 className="grabb blinking-text">
                                      GRABB !
                                    </h1>
                                  ) : (
                                    <div className="jbtw col-12">
                                      <img
                                        src={p1}
                                        alt="not available"
                                        style={{
                                          height: '25vh',
                                          width: '150px',
                                          textAlign: 'center',
                                        }}
                                        className="blinking-text mx-auto"
                                      />

                                      <img
                                        style={{
                                          height: '25vh',
                                          width: '150px',
                                          textAlign: 'center',
                                        }}
                                        className="blinking-text mx-auto"
                                        src={p2}
                                        alt="not available"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="rounded py-2 col-12 bala align-items-center jcenter tanyad">
                                <div className="rounded col-12 jevenly align-items-center">
                                  {grabb ? (
                                    <h1 className="grabb blinking-text">
                                      <div className="text-center">
                                        <h3>
                                          Pls wait for other members to join{' '}
                                        </h3>
                                        <br />
                                        <FontAwesomeIcon
                                          className="text-light mx-auto blinking-text"
                                          icon={faAngleDoubleDown}
                                        />
                                      </div>
                                    </h1>
                                  ) : (
                                    <div className="rounded jbtw col-12">
                                      <img
                                        src={p1}
                                        alt="not available"
                                        style={{
                                          height: '25vh',
                                          width: '150px',
                                          textAlign: 'center',
                                        }}
                                        className="blinking-text mx-auto"
                                      />

                                      <img
                                        style={{
                                          height: '25vh',
                                          width: '150px',
                                          textAlign: 'center',
                                        }}
                                        className="blinking-text mx-auto"
                                        src={p2}
                                        alt="not available"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          ) : (
                            <div
                              style={{ borderRadius: '155%' }}
                              className="rounded py-2 col-12 bala align-items-center jcenter tanyad"
                            >
                              <div className=" col-12 jevenly align-items-center">
                                {grabb ? (
                                  <h1 className="grabb blinking-text">
                                    <div className="text-center">
                                      <h3>Raise/Join Quest </h3>
                                      <br />
                                      <FontAwesomeIcon
                                        className="text-light mx-auto blinking-text"
                                        icon={faAngleDoubleDown}
                                      />
                                    </div>
                                  </h1>
                                ) : (
                                  <div className="jbtw col-12">
                                    <img
                                      src={p1}
                                      alt="not available"
                                      style={{
                                        height: '25vh',
                                        width: '150px',
                                        textAlign: 'center',
                                      }}
                                      className="blinking-text mx-auto"
                                    />

                                    <img
                                      style={{
                                        height: '25vh',
                                        width: '150px',
                                        textAlign: 'center',
                                      }}
                                      className="blinking-text mx-auto"
                                      src={p2}
                                      alt="not available"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          style={{ margin: '2vh 0' }}
                          className="col-md-6 col-12  align-items-center mx-auto  text-light"
                        >
                          {user.ingame ? (
                            <div className="jbtw">
                              <Link to="/history">
                                <Buttonr variant="warning">
                                  Quest history
                                </Buttonr>
                              </Link>
                              {user.questadmin ? (
                                <div className="jcenter">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        Are you sure you want to delete this
                                        quest ?
                                      </Tooltip>
                                    }
                                  >
                                    <Buttonr
                                      onClick={Delquest}
                                      variant="danger"
                                    >
                                      Delete this quest
                                    </Buttonr>
                                  </OverlayTrigger>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          ) : (
                            <div className="jbtw">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    This creates an open group with staked
                                    amount , where other interested players can
                                    join , players will compete for the total
                                    staked amount{' '}
                                    <span className="text-danger">
                                      (-10% total staked)
                                    </span>{' '}
                                    of the first to get more than 100 points{' '}
                                  </Tooltip>
                                }
                              >
                                <Buttonr
                                  variant="success"
                                  onClick={() => handleOpentt('rq')}
                                >
                                  Raise Quest
                                </Buttonr>
                              </OverlayTrigger>

                              {quests.length > 0 ? (
                                <Buttonr variant="warning" onClick={Getquests}>
                                  Join Quest {quests.length}(s)
                                </Buttonr>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-tooltip">
                                      There are currently no quests now
                                    </Tooltip>
                                  }
                                >
                                  <Buttonr
                                    variant="warning"
                                    onClick={Getquests}
                                  >
                                    Join Quest
                                  </Buttonr>
                                </OverlayTrigger>
                              )}
                              <Link to="/history">
                                <Buttonr variant="danger">
                                  Quest history
                                </Buttonr>
                              </Link>
                            </div>
                          )}
                          <Dialog
                            open={openDialogId === 'rq'}
                            onClose={handleClosee}
                            className="col-12 w-100"
                          >
                            <DialogTitle>
                              <h1>Create New Quest !</h1>
                            </DialogTitle>
                            <DialogContent>
                              <div className="my-4">
                                <h4 className="text-center">
                                  Set Quest amount{' '}
                                </h4>
                                <div className="jbtw col-12">
                                  <Buttonr variant="danger" onClick={Cm}>
                                    <FontAwesomeIcon
                                      className="text-light mx-auto"
                                      icon={faMinus}
                                    />{' '}
                                    {vala}
                                  </Buttonr>
                                  <input
                                    type="number"
                                    name=""
                                    id=""
                                    readOnly
                                    value={stake}
                                    placeholder="stake amount"
                                    style={{ outline: 'none' }}
                                    className="text-center  border-0"
                                  />
                                  <Buttonr
                                    variant="success"
                                    className=""
                                    onClick={Cp}
                                  >
                                    <FontAwesomeIcon
                                      className="text-light mx-auto"
                                      icon={faPlus}
                                    />
                                    {' ' + vala}
                                  </Buttonr>
                                </div>
                                <div className="jbtw">
                                  <p
                                    onClick={() => setVala(500)}
                                    className="tida cpp "
                                  >
                                    +500
                                  </p>
                                  <p
                                    onClick={() => setVala(1000)}
                                    className="tida cpp "
                                  >
                                    +1,000
                                  </p>
                                  <p
                                    onClick={() => setVala(10000)}
                                    className="tida cpp "
                                  >
                                    +10,000
                                  </p>
                                  <p
                                    onClick={() => setVala(100000)}
                                    className="tida cpp"
                                  >
                                    +100,000
                                  </p>
                                </div>
                              </div>
                              <div className="my-4">
                                <h4 className="text-center">
                                  Maximum number of players
                                </h4>
                                <div className="jbtw col-12 border-danger">
                                  <Buttonr variant="danger" onClick={Inclientm}>
                                    <FontAwesomeIcon
                                      className="text-light mx-auto"
                                      icon={faMinus}
                                    />
                                  </Buttonr>
                                  <input
                                    type="number"
                                    name=""
                                    id=""
                                    readOnly
                                    value={clients}
                                    placeholder="maximum number of players"
                                    style={{ outline: 'none' }}
                                    className="text-center  border-0"
                                  />
                                  <Buttonr variant="success" onClick={Inclient}>
                                    <FontAwesomeIcon
                                      className="text-light mx-auto"
                                      icon={faPlus}
                                    />
                                  </Buttonr>
                                </div>
                              </div>
                              <div className="jcenter text-center mt-3">
                                {!onit ? (
                                  <Buttonr variant="primary" onClick={Createq}>
                                    Create Quest
                                  </Buttonr>
                                ) : (
                                  <Buttonr
                                    variant="primary"
                                    className="jcenter"
                                  >
                                    <CircularProgress className="text-light blinking-text" />
                                  </Buttonr>
                                )}
                              </div>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClosee}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                        {quest ? (
                          <div className="text-center">
                            <span
                              variant="warning"
                              className="text-light text-center mx-auto"
                            >
                              Admin:{quest.adminame}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </div>

                  {showModal && (
                    <div className="modalf position-absolute z-3">
                      <div className="modal-content position-absolute  text-center">
                        <h2
                          className="text-light"
                          style={{ textShadow: '2px 4px 4px black' }}
                        >
                          Congratulations!
                        </h2>
                        <img
                          className="ml-5 tida"
                          style={{
                            objectFit: 'contain',
                            width: '100px',
                            borderRadius: '50%',
                          }}
                          src="/images/cong.gif"
                          alt="You won"
                        />
                        <p className="text-light text-center tida">
                          Wow ! you just won &nbsp;
                          <span
                            className=""
                            style={{
                              fontSize: '25px',
                              fontWeight: '900',
                              color: 'gold',
                              textShadow: '2px 4px 4px green',
                            }}
                          >
                            {money(newstake * 2)} &nbsp;
                          </span>
                          !!
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Team;
