import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Adminh from './Adminh';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCog, FaSignOutAlt } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  faDice,
  faGamepad,
  faUser,
  faExchangeAlt,
  faHome,
  faUserShield,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import Button from 'react-bootstrap/Button';
// import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

import { FaAngleDoubleRight } from 'react-icons/fa';

const Admin = ({ cont,setProfits, user, setUser, games,data,setData,setPlayers, settings }) => {
  function money(amount) {
    var moneyFormat = Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    const dr = '₦' + moneyFormat.split('NGN')[1];
    return dr;
  }
  const jwtt = Cookies.get('jwt');
  const [loading, setLoading] = useState(true);
  
  const [first, setFirst] = useState(false);
//   setProfits(data.profit)
  const navigate = useNavigate();
  const [things, setThings] = useState({});
  const elementRef = useRef(null);
 
  useEffect(() => {
    const Check = async () => {
      try {
        await axios.get(`${cont.api}getdata`, { jwtt }).then((res) => {
          if (res.data.success) {
            // setFirst(false);

            if (res.data.data) {
            //   setFirst(false);

              setData(res.data.data);
            //   alert(res.data.data.latestloginplayertime);
              setPlayers(res.data.players);
              setLoading(false)
              // setFirst(false);

              // navigate('/admin');
            } else {
              Signout();
            }
          } else {
            Signout();
          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
      }
    };
    let checkCalled = false;

    // Check if jwtt and first are truthy
    if (jwtt  &&  !checkCalled) {
      // Call Check() if conditions are met
      Check();

      // Set the flag to true to indicate that Check() has been called
      checkCalled = true;
    }

    // Cleanup function (runs when component unmounts)
    // return () => {
    //   // Reset the 'first' state when the component unmounts
    //   setFirst(false);
    //   checkCalled = true;

    // };
  }, [jwtt,setData]);

 
  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };

  return (
    <div>
      <Adminh
        user={user}
        setUser={setUser}
        cont={cont}
        db={true}
        data={data}
        className=""
      />

      <div className="main-bannerb mb-0 ">
        <div class="sectionk trending ">
          <div class="container mt-2">
            <nav
              position="sticky"
              className="main-nav col-12 d-md-none text-light"
            >
              <ul className="nav text-center justify-content-between d-flex">
                <li className="text-light">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id={`tooltip`}>Home</Tooltip>}
                  >
                    <Link
                      to="/"
                      style={{ color: 'white' }}
                      className={[games ? 'active activeb' : '', 'text-light']}
                    >
                      <FontAwesomeIcon icon={faHome} />
                    </Link>
                  </OverlayTrigger>
                </li>

                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip`}>View all transactions</Tooltip>
                    }
                  >
                    <Link style={{ color: 'white' }} to="/atrans">
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id={`tooltip`}>View Players</Tooltip>}
                  >
                    <Link style={{ color: 'white' }} to="/players">
                      <FontAwesomeIcon icon={faUsers} />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip`}>Perform admin duties</Tooltip>
                    }
                  >
                    <Link
                      style={{ color: 'white' }}
                      className="active activeb tida"
                      to="/admin"
                    >
                      <FontAwesomeIcon icon={faUserShield} />
                    </Link>
                  </OverlayTrigger>
                </li>
                {/* <li>
                  <Link style={{ color: 'white' }} to="/settings">
                    <FaCog />
                  </Link>
                </li> */}

                {/* <li className="bg-danger rounded p-2 text-success">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip`}>Total profit </Tooltip>
                    }
                  >
                    <Link
                      style={{ color: 'white', fontStyle: 'normal' }}
                      to="/atrans"
                    >
                      {data.profit}
                    </Link>
                  </OverlayTrigger>
                </li> */}
              </ul>
            </nav>
            <div class="row">
              <div class="col-lg-6">
                <div class="section-heading">
                  {/* <h6>Trending</h6> */}
                  <h2 ref={elementRef} className="text-light tida">
                    Analytics
                  </h2>
                </div>
              </div>
              <div class="col-lg-6">
                {/* <div class="main-button">
                    <a href="shop.html">View All</a>
                  </div> */}
              </div>
              {loading ? (
                <div className="mt-5 text-center">
                  <h3 className="text-light blinking-text">
                    loading ...{' '}
                    <Spinner
                      className="mx-auto text-center "
                      animation="border"
                      variant="light"
                      style={{ fontSize: '50px' }}
                    />
                  </h3>
                </div>
              ) : (
                <div>
                  <div className="row justify-content-evenly mx-auto place-items-center">
                    <div class="col-lg-3 col-md-6  ">
                      <div class="item">
                        <div class="thumb text-center ">
                          <div>
                            <img
                              src="/images/money.gif"
                              className="dimagej"
                              height="150px"
                              alt=""
                              style={{ objectFit: 'cover' }}
                            />
                          </div>
                        </div>
                        <div class="down-content">
                          <span class="category">Total profit</span>
                          <h4 className="tidaa">{data.profit} </h4>
                          {/* <Link to="/product-details.html">
                        <FontAwesomeIcon icon={faDice} />
                      </Link> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3  text-center col-md-6 ">
                      <div class="item">
                        <div class="thumb text-center ">
                          <div>
                            <img
                              src="/images/profit.gif"
                              className="dimagej"
                              height="150px"
                              alt=""
                              style={{ objectFit: 'cover' }}
                            />
                          </div>
                        </div>
                        <div class="down-content">
                          <span class="category">Win reserve</span>
                          <h4 className="tidaa">{data.reserveforwins} </h4>
                          {/* <Link to="/product-details.html">
                        <FontAwesomeIcon icon={faDice} />
                      </Link> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center mx-auto">
                      <div class="item">
                        <div class="thumb text-center mx-auto">
                          <div>
                            <img
                              src="/images/cash.gif"
                              className="dimagej"
                              height="150px"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="down-content">
                          <span class="category">Sixtus Accounts</span>
                          <h4 className="tidaa">{money(data.untouch)} </h4>
                          {/* <Link to="/product-details.html">
                        <FontAwesomeIcon icon={faDice} />
                      </Link> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mx-auto">
                      <div class="item">
                        <div class="thumb text-center mx-auto">
                          <div>
                            <img
                              src="/images/ballers.gif"
                              className="dimage"
                              height="150px"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="down-content">
                          <span class="category">Players</span>
                          <h4 className="tidaa">{data.users} </h4>
                          {/* <Link to="/product-details.html">
                        <FontAwesomeIcon icon={faDice} />
                      </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <h3 className="text-center text-light tida">Controls</h3>
                  <div className="text-center jbtw">
                    <Link
                      to="/virtual"
                      className="bg-success"
                      
                    >
                      <Button variant="success">Virtual</Button>{' '}
                    </Link>{' '}
                    <Button variant="warning">Club winnings</Button>{' '}
                    <Button variant="success">Virtual Winners</Button>{' '}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {data ? (
        <div className="features mt-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 mx-auto">
                <div>
                  <div className="item text-center border">
                    <span>Latest sign up</span>
                    <h4>{data.newestplayer}</h4>
                    <small>@{data.newestplayertime}</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mx-auto">
                <div>
                  <div className="item text-center border">
                    <span>Latest login</span>
                    <h4>{data.latestloginplayer}</h4>
                    <small>@{data.latestloginplayertime}</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mx-auto">
                <div>
                  <div className="item text-center border">
                    <span>Latest deposit</span>
                    <h4>{data.latestdeposittime}</h4>
                    <small>@{data.newestplayertime}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Admin;
