import React, { useEffect, useState, useRef } from "react";

import Header from "./Header";
import { useSpring, animated } from "react-spring";
import TextLoop from "react-text-loop";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { FaCog, FaSignOutAlt } from "react-icons/fa";
import Cookies from "js-cookie";
import { TERipple } from "tw-elements-react";
import Table from "react-bootstrap/Table";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import axios from "axios";
import Adminh from "./Adminh";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDice,
  faGamepad,
  faUser,
  faExchangeAlt,
  faHome,
  faUserShield,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import Typography from "@mui/material/Typography";

function money(amount) {
  var moneyFormat = Number(amount).toLocaleString("en-US", {
    style: "currency",
    currency: "NGN",
  });
  const dr = "₦" + moneyFormat.split("NGN")[1];
  return dr;
}
const Trans = ({ user, setUser, cont, data, games, settings }) => {
  const jwtt = Cookies.get("jwt");
  const [trans, setTrans] = useState([]);
  const [pp, setPp] = useState(true);
  const [loading, setLoading] = useState(true);

  const [deposits, setDeposits] = useState([]);
  const [withs, setWiths] = useState([]);

  const [first, setFirst] = useState(true);
  const [start, setStart] = useState([0]);
  // const [transactions,setTransactions] = useState()
  const navigate = useNavigate();
  const Check = async () => {
    try {
      await axios.get(`${cont.api}alltrans`).then((res) => {
        if (res.data.success) {
          if (res.data.alltrans) {
            setTrans(res.data.alltrans);
            const nd = res.data.alltrans.filter((el) => el.deposit);
            const wd = res.data.alltrans.filter((el) => !el.deposit);
            setTrans(res.data.alltrans);
            setDeposits([...nd]);
            setWiths([...wd]);
            setLoading(false);
          } else {
            Signout();
          }
        } else {
          Signout();
        }
      });
    } catch (error) {
      // Handle error if needed
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (jwtt && first) {
      Check();
    } else if (!user) {
      navigate("/");
    }
  }, [jwtt, user]);
  // useEffect((trans) => {
  //   axios.get(`${cont.api}gettrans/${start}`)
  // }, [cont.api,start]);
  const [newdep, setDep] = useState(1000);

  const [showModal, setShowModal] = useState(false);

  const elementRef = useRef(null);

  const Signout = () => {
    setUser(false);
    Cookies.remove("jwt");
    navigate("/");
  };
  const Pay = async(ref, start) => {
    try {
      const formDataToSend = new FormData();

      formDataToSend.append("ref", ref);
      formDataToSend.append("status", start);
      await axios.post(`${cont.api}accept`, formDataToSend).then((res) => {
        if (res.data.success) {
          if (res.data.alltrans) {
            setTrans(res.data.alltrans);
            const nd = res.data.alltrans.filter((el) => el.deposit);
            const wd = res.data.alltrans.filter((el) => !el.deposit);
            setTrans(res.data.alltrans);
            setDeposits([...nd]);
            setWiths([...wd]);
            setLoading(false);
          } else {
            Signout();
          }
        } else {
          Signout();
        }
      });
    } catch (error) {
      // Handle error if needed
      console.error("Error:", error);
    }
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <div className="">
      <Adminh
        user={user}
        setUser={setUser}
        cont={cont}
        ifplayers={true}
        data={data}
        className=""
      />

      <div className="main-bannerc mb-0 ">
        <div class="sectionj trending ">
          <div class="container" style={{ minHeight: "90vh" }}>
            <div class="d-flex align-items-center  my-2">
              <div class="col-md-6 col-6">
                <div class="section-headingj d-flex justify-content-between">
                  {/* <h6>Trending</h6> */}
                  <h3 className="text-light tida">Admin Transactions</h3>
                </div>
              </div>
            </div>
            <div className="div px-1">
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="mb-3 px-2 text-center bg-transparent jcenter align-items-center"
              >
                <Tab
                  eventKey="all"
                  className=" col-12"
                  title={
                    <span className="custom-tab-title bg-primary w-100 px-5 py-2  ">
                      All Transactions
                    </span>
                  }
                >
                  <h3 className="text-light tida">All transaction(s)</h3>
                  {loading ? (
                    <div className="mt-5 text-center">
                      <h3 className="text-light blinking-text">
                        loading ...{" "}
                        <Spinner
                          className="mx-auto text-center "
                          animation="border"
                          variant="light"
                          style={{ fontSize: "50px" }}
                        />
                      </h3>
                    </div>
                  ) : (
                    <div
                      className="table-container table-responsive rela justify-content-center  mx-auto rounded mt-1 col-12 text-light"
                      style={{ maxHeight: "40vh", overflow: "scroll" }}
                    >
                      {trans.length > 0 ? (
                        <Table
                          striped
                          bordered
                          hover
                          responsive
                          size="sm"
                          className="py-3 table overflow-auto rounded text-center"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Date</th>

                              <th>Reference</th>
                              <th>Amount</th>
                              <th>Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {trans.map((el, index) => (
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td
                                  className=""
                                  style={{ textShadow: "0 1px 1px white" }}
                                >
                                  {el.name}
                                </td>
                                <td style={{ textShadow: "0 2px 1px white" }}>
                                  {el.date}
                                </td>
                                <td>{el.reference}</td>
                                <td
                                  className={
                                    el.deposit ? "text-success" : "text-danger"
                                  }
                                >
                                  {el.damount}
                                </td>
                                <td
                                  className={
                                    el.deposit
                                      ? "text-success font-weight-bolder"
                                      : "text-danger font-weight-bolder"
                                  }
                                >
                                  {el.deposit ? "Deposit" : "Withdrawal"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center mt-5 text-light">
                          No transactions yet
                        </div>
                      )}
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="home"
                  className=" col-12"
                  title={
                    <span className="custom-tab-title bg-primary w-100 px-5 py-2  ">
                      Deposits
                    </span>
                  }
                >
                  <h3 className="text-light tida">Deposit(s)</h3>
                  {loading ? (
                    <div className="mt-5 text-center">
                      <h3 className="text-light blinking-text">
                        loading ...{" "}
                        <Spinner
                          className="mx-auto text-center "
                          animation="border"
                          variant="light"
                          style={{ fontSize: "50px" }}
                        />
                      </h3>
                    </div>
                  ) : (
                    <div
                      className="table-container table-responsive rela justify-content-center  mx-auto rounded mt-1 col-12 text-light"
                      style={{
                        maxHeight: "70vh",
                        minHeight: "40vh",
                        overflow: "scroll",
                      }}
                    >
                      {deposits && deposits.length > 0 ? (
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          className="py-3 text-center table overflow-auto rounded "
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Reference</th>
                              <th>Amount</th>
                              {/* <th>Type</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {deposits.map((el, index) => (
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{el.date}</td>
                                <td>{el.reference}</td>
                                <td>{el.damount}</td>
                                {/* <td
                                  className={
                                    el.deposit
                                      ? "text-success font-weight-bolder"
                                      : "text-danger font-weight-bolder"
                                  }
                                >
                                  {el.deposit ? "Deposit" : "Withdrawal"}
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center  text-light">
                          No deposit(s) yet
                        </div>
                      )}
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="profile"
                  className=" col-12"
                  title={
                    <span className="custom-tab-title bg-primary w-100 px-5 py-2  ">
                      Withdrawals
                    </span>
                  }
                >
                  <h3 className="text-light tida">Withdrawal(s)</h3>
                  {loading ? (
                    <div className="mt-5 text-center">
                      <h3 className="text-light blinking-text">
                        loading ...{" "}
                        <Spinner
                          className="mx-auto text-center "
                          animation="border"
                          variant="light"
                          style={{ fontSize: "50px" }}
                        />
                      </h3>
                    </div>
                  ) : (
                    <div
                      className="table-container table-responsive rela justify-content-center  mx-auto rounded mt-1 col-12 text-light"
                      style={{
                        maxHeight: "70vh",
                        minHeight: "40vh",
                        overflow: "scroll",
                      }}
                    >
                      {withs && withs.length > 0 ? (
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          className="py-3 text-center table overflow-auto rounded "
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Names</th>
                              <th>Reference</th>
                              <th>Bank</th>
                              <th>Account number</th>
                              <th>Amount</th>
                              <th>Act</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withs.map((el, index) => (
                              <tr key={el.reference}>
                                <td>{index + 1}</td>
                                <td>{el.date}</td>
                                <td>{el.name} {el.dummy?'(d.accnt)':''}</td>
                                <td>{el.reference}</td>
                                <td>{el.bankname}</td>
                                <td>{el.bankaccount}</td>
                                <td>{el.damount}</td>
                                <td>
                                  {el.pending ? (
                                    <div className="jbtw">
                                      <h6 className=" text-warning">Pending</h6>
                                      <small
                                        className="cpp fa fa-times text-danger"
                                        onClick={(e) => {
                                          Pay(el.reference, "reject");
                                        }}
                                      >
                                        Reject
                                      </small>
                                      <small
                                        className="cpp fa fa-check text-success"
                                        onClick={(e) => {
                                          Pay(el.reference, "accept");
                                        }}
                                      >
                                        Accept
                                      </small>
                                    </div>
                                  ) : el.failed ? (
                                    <h6 className="text-danger">Rejected</h6>
                                  ) : (
                                    <h6 className="text-success">Paid</h6>
                                  )}
                                </td>
                                {/* <td
                                  className={
                                    el.deposit
                                      ? "text-success font-weight-bolder"
                                      : "text-danger font-weight-bolder"
                                  }
                                >
                                  {el.deposit ? "Deposit" : "Withdrawal"}
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center  text-light">
                          No Withdrawal(s) yet
                        </div>
                      )}
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* <h5>hgfhj</h5> */}
    </div>
  );
};

export default Trans;
