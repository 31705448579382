import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import Countdown from 'react-cntdwn';

import Mheader from './Mheader';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import Swal from 'sweetalert2';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaCog, FaSignOutAlt, FaMinus, FaPlus } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { TERipple } from 'tw-elements-react';
import axios from 'axios';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  faDice,
  faGamepad,
  faUser,
  faVolumeMute,
  faInfoCircle,
  faPlay,
  faAngleDoubleDown,
  faMinus,
  faPlus,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as Buttonr } from 'react-bootstrap';
import CssBaseline from '@mui/material/CssBaseline';
import WebSocketClient from 'websocket';

// import '@fortawesome/fontawesome-free/css/all.min.css';
function money(amount) {
  var moneyFormat = Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  const dr = '₦' + moneyFormat.split('NGN')[1];
  return dr;
}
const Records = ({
  quest,
  setQuest,
  quests,
  setQuests,
  user,
  setUser,
  cont,
  
}) => {
  // alert(JSON.stringify(user))
  // const game = games[1];
  const [nroll, setNroll] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [box, setBox] = useState(false);
  const [mute, setMute] = useState(false);
  const jwtt = Cookies.get('jwt');
  const openModal = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 16000); // Adjust the time (in milliseconds) according to your needs
  };
  
  const [newstake, setNewstake] = useState(100);
  
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [rolls, setRolls] = useState();
  const [loading, setLoading] = useState(true);
  
  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };

  const [navigationCount, setNavigationCount] = useState(0);

  const [openDialogId, setOpenDialogId] = useState(null);

  const handleClosee = () => {
    setOpenDialogId(null);
    // handleClose();
  };

  
  const cols = [
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
  ];
  const handleOpentt = (id) => {
    setOpenDialogId(id);
  };
    // alert(user.userid);
  
  useEffect(() => {
    let ui = true
    const yourFunction = async () => {
      if (user) {
        // alert('yes quest')
        try {
          const response = await axios.get(
            `${cont.api}hisrolls/${user.userid}`
          );
          // alert(user.userid);
          const responseData = response.data;
          if (responseData.success && responseData.user) {
            setUser(responseData.user);
            setLoading(false)
            // setQuest(responseData.quest);
            setRolls(responseData.rolls);
            // setRoll(responseData.roll);

            // setQuests(responseData.quests);
            // if (rolls.length > 0) {

            // } else {
            //   setRolls(false);
            // }
            // alert('set rolls ' + responseData.rolls.length);
          } else {
            // Handle other conditions if needed
            navigate('/double');

          }
        } catch (err) {
          console.error(err);
          // Handle error if needed
        }
      } else {
        // alert('sgh')
      }
      ui = false
    };
    // if(ui){
      yourFunction();
    // };
    //
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  
  return (
    <div>
      <div>
        <CssBaseline />

        <Mheader
          user={user}
          setUser={setUser}
          cont={cont}
          games={false}
          className=""
        />

        {user ? (
          <div className="main-bannerc my-auto jalign">
            <div class="sectionj trending ">
              <div class="container" style={{ height: '120vh' }}>
                <div class="row justify-content-between ">
                  <div class="col-lg-12 mb-0" style={{ height: '70vh' }}>
                    <div class="section-heading mb-0">
                      <div className="caption header-text jbtw">
                        <div className="jbtw jalign align-items-center">
                          <h3 className="text-light tida">Quest History</h3>{' '}
                          &nbsp;
                        </div>

                        <div className="my-2 mx-1">
                          <div
                            onClick={() => handleOpentt('we')}
                            className=" col-12 btn btn-danger  text-light rounded rounded tida"
                          >
                            {loading ? (
                              <Spinner animation="border" variant="light" />
                            ) : rolls && rolls.length > 0 ? (
                              `${rolls.length} Quests`
                            ) : (
                              `No Quest`
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {loading ? (
                      <div className='text-center mt-5'>
                        
                        <Spinner
                          className="mx-auto text-center "
                          animation="border"
                          variant="light"
                          style={{fontSize:'100px'}}
                        />
                      </div>
                    ) : (
                      <div
                        className="mt-3 row justify-content-evenly mx-auto place-items-center"
                        style={{ minHeight: '50vh' }}
                      >
                        {rolls && rolls.length > 0 ? (
                          <div className="table-responsive">
                            <Table
                              striped
                              bordered
                              hover
                              size="sm"
                              className="py-3 text-center table overflow-auto rounded"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Date</th>
                                  <th>Quest admin</th>
                                  <th>Quest id</th>
                                  <th>Grabbs</th>
                                  <th>Points</th>
                                  <th>Status</th>

                                  {/* <th>Result</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {rolls.map((el, index) => (
                                  <tr
                                    // onClick={() => handleOpentt(el.questid)}
                                    key={index + 1}
                                    className={[el.won ? ' ' : '', cols[index]]}
                                  >
                                    <td>{index + 1}</td>
                                    {/* <Dialog
                                    open={openDialogId === el.questid}
                                    onClose={handleClosee}
                                    show={show}
                                  >
                                    <DialogTitle>
                                      Quest Record History ?
                                    </DialogTitle>
                                    <DialogContent className="text-center">
                                      {rolls && rolls.length > 0 ? (
                                        <div>
                                          <Table
                                            striped
                                            bordered
                                            hover
                                            size="sm"
                                            className="py-3 text-center table overflow-auto rounded"
                                          >
                                            <thead>
                                              <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Quest admin</th>
                                                <th>Quest id</th>
                                                <th>Grabbs</th>
                                                <th>Points</th>
                                                <th>Status</th>

                                              </tr>
                                            </thead>
                                            <tbody>
                                              {rolls.map((el, index) => (
                                                <tr
                                                  
                                                  key={index + 1}
                                                  className={[
                                                    el.won ? ' ' : '',
                                                    cols[index],
                                                  ]}
                                                >
                                                  <td>{index + 1}</td>
                                                  
                                                  <td>{el.joined}</td>
                                                  <td>{el.adminname}</td>
                                                  <td>{el.questid}</td>
                                                  <td>{el.nroll}</td>
                                                  <td>{el.rolled}</td>
                                                  <td
                                                    className={
                                                      el.won
                                                        ? 'blinking-text text-success'
                                                        : el.status ===
                                                          'in progress'
                                                        ? 'text-warning'
                                                        : 'text-danger'
                                                    }
                                                  >
                                                    {el.won
                                                      ? el.dwinamount
                                                      : el.status ===
                                                        'in progress'
                                                      ? 'in progress'
                                                      : el.dquesthold}
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </Table>

                                          
                                        </div>
                                      ) : (
                                        <div className="text-center  text-light mt-5">
                                          No Records yet
                                        </div>
                                      )}
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={handleClosee}>
                                        Close
                                      </Button>
                                    </DialogActions>
                                  </Dialog> */}
                                    <td>{el.joined}</td>
                                    <td>{el.adminname}</td>
                                    <td>{el.questid}</td>
                                    <td>{el.nroll}</td>
                                    <td>{el.rolled}</td>
                                    <td
                                      className={
                                        el.won
                                          ? ' text-success'
                                          : el.status === 'in progress'
                                          ? 'text-warning'
                                          : 'text-danger'
                                      }
                                    >
                                      {el.won ? (
                                        <div>
                                          {el.dwinamount}{' '}
                                          {/* <FontAwesomeIcon icon={faCheck} /> */}
                                        </div>
                                      ) : el.status === 'in progress' ? (
                                        'in progress'
                                      ) : (
                                        el.dquesthold
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                            {/* {quest.completed ? (
                            roll.won ? (
                              <div>
                                <h1 className="text-center text-success blinking-text">
                                  Congratulations ! {`+ ${roll.dwinamount}`}
                                </h1>
                              </div>
                            ) : (
                              <div>
                                <h1 className="text-center text-danger blinking-text">
                                  OOps , you lost !
                                </h1>
                              </div>
                            )
                          ) : (
                            <div>
                              <h3 className="text-center text-light blinking-text">
                                Still in progress
                              </h3>
                            </div>
                          )} */}
                          </div>
                        ) : (
                          <div className="text-center  text-light mt-5">
                            No Records yet
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      <div className="text-end">
                        <Link to="/double">
                          <Buttonr variant="warning">Go back</Buttonr>
                        </Link>
                        {/* <Link to="/history">
                          <Buttonr variant="warning">Quest history</Buttonr>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Records;
