import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Adminh from './Adminh';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
 
  faGamepad,
  faUser,
  faExchangeAlt,
  faHome,
  faUserShield,
  faUsers,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
// import { faDice, faGamepad, faUser } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table';

import axios from 'axios';
import Cookies from 'js-cookie';
// import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';



const Players = ({
  cont,
  user,
  data,
  setUser,
  players,
  setPlayers,
  profits,
}) => {
  function money(amount) {
    var moneyFormat = Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    const dr = '₦' + moneyFormat.split('NGN')[1];
    return dr;
  }
 
  const jwtt = Cookies.get('jwt');
  const navigate = useNavigate();
  const Deluser =async(userid,name)=>{
    
      async function Goon (){
        await axios.get(`${cont.api}deluser/${userid}`, { jwtt }).then((res) => {
          if (res.data.success) {
            // setFirst(false);

            if (res.data.players) {
              
              //   alert(res.data.data.latestloginplayertime);
              setPlayers(res.data.players);
              Swal.fire({
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                icon: 'success',
              });
            }
          } else {
            setPlayers(res.data.players)
            Swal.fire({
              title: 'Not Deleted!',
              text: res.data.message
                ? res.data.message
                : 'something went wrong deleting this file.',
              icon: 'error',
            });
          }
        });

        
      }
      Swal.fire({
        title: `Are you sure you want to delete ${name}'s account ?`,
        text: `You won't be able to revert this ! `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          Goon();
        }
      });
    
  }
  const Assd =async(userid,name)=>{
    
      async function Goon (){
        await axios
          .get(`${cont.api}assigndummy/${userid}`, { jwtt })
          .then((res) => {
            if (res.data.success) {
              // setFirst(false);

              if (res.data.players) {
                //   alert(res.data.data.latestloginplayertime);
                setPlayers(res.data.players);
                
              }
            } else {
              setPlayers(res.data.players);
              
            }
          });

        
      }
      Goon();
    
  }
  const Act =async(userid,act)=>{
    
        await axios.get(`${cont.api}act/?userid=${userid}&act=${act}`, { jwtt }).then((res) => {
          if (res.data.success) {
            // setFirst(false);

            if (res.data.players) {
              
              //   alert(res.data.data.latestloginplayertime);
              setPlayers(res.data.players);
              // setUser(res.data.user);
              // Swal.fire({
              //   title: 'Account updated !',
              //   text: 'Action was successful.',
              //   icon: 'success',
              // });
            }
          } else {
            setPlayers(res.data.players)
            Swal.fire({
              title: 'Error can not perform action now !',
              text: res.data.message
                ? res.data.message
                : 'something went wrong .',
              icon: 'error',
            });
          }
        });

        
     
      
    
  }
  const elementRef = useRef(null);
  useEffect(() => {
    const Check = async () => {
      try {
        await axios.get(`${cont.api}getplayers`, { jwtt }).then((res) => {
          if (res.data.success) {
            // setFirst(false);

            if (res.data.players) {
              
              //   alert(res.data.data.latestloginplayertime);
              setPlayers(res.data.players);
              // setFirst(false);

              // navigate('/admin');
            }
          } else {
            navigate('/')
          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
      }
    };
    let checkCalled = false;

    // Check if jwtt and first are truthy
    if (jwtt && !checkCalled) {
      // Call Check() if conditions are met
      Check();

      // Set the flag to true to indicate that Check() has been called
      checkCalled = true;
    }

  }, [jwtt, setPlayers]);
  const tooltip = <Tooltip id="tooltip">Tooltip text</Tooltip>;

  return (
    <div>
      <Adminh
        user={user}
        setUser={setUser}
        cont={cont}
        ifplayers={true}
        data={data}
        className=""
      />

      <div className="main-bannerb mb-0 ">
        <div class="sectionj trending ">
          <div class="container" style={{ minHeight: "90vh" }}>
            <nav className="main-nav col-12 d-md-none text-light">
              <ul className="nav text-center justify-content-between d-flex">
                <li className="text-light">
                  <Link
                    to="/"
                    style={{ color: "white" }}
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faHome} />
                  </Link>
                </li>

                <li>
                  {/* <a href={contact}>Accounts</a> */}
                  <Link style={{ color: "white" }} to="/atrans">
                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </Link>
                </li>
                <li>
                  <Link
                    className="active activeb tida"
                    style={{ color: "white" }}
                    to="/players"
                  >
                    <FontAwesomeIcon icon={faUsers} />
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "white" }} to="/admin">
                    <FontAwesomeIcon icon={faUserShield} />
                  </Link>
                </li>
                {/* <li>
                  <Link style={{ color: 'white' }} to="/settings">
                    <FaCog />
                  </Link>
                </li> */}

                <li className="bg-danger rounded p-2 text-success">
                  <Link
                    style={{ color: "white", fontStyle: "normal" }}
                    to="/atrans"
                  >
                    {data.profit}
                  </Link>
                </li>
              </ul>
            </nav>
            <div class="row">
              <div class="col-lg-6">
                <div class="section-heading">
                  {/* <h6>Trending</h6> */}
                  <h2 ref={elementRef} className="text-light tida">
                    Players
                  </h2>
                </div>
              </div>
              <div class="col-lg-6">
                {/* <div class="main-button">
                    <a href="shop.html">View All</a>
                  </div> */}
              </div>
              <div className="row justify-content-evenly mx-auto place-items-center table-responsive">
                {players.length > 0 ? (
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    className="py-3 table overflow-auto rounded text-center"
                  >
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        <th>Name</th>
                        <th className="d-none d-md-block">Login times</th>
                        <th>Losses/Wins</th>
                        <th>Account balance</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Y/N Dummy</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {players.map((el, index) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                {el.email}
                                <br />
                                {el.passwordb}
                                <br />
                                login time(s):{el.logintimes}
                              </Tooltip>
                            }
                          >
                            <td>{el.name}</td>
                          </OverlayTrigger>

                          <td className="d-none d-md-block">
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  last seen:{el.lastseen}
                                </Tooltip>
                              }
                            >
                              <div>{el.logintimes}</div>
                            </OverlayTrigger>
                          </td>

                          <td>
                            {el.dlosses}/{el.dwins}
                          </td>

                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                won :{el.dwins}
                                <br />
                                lost:{el.dlosses}
                              </Tooltip>
                            }
                          >
                            <td
                              className={
                                el.total > 999
                                  ? "text-success font-weight-bolder"
                                  : "text-danger"
                              }
                            >
                              {el.dtotal}
                            </td>
                          </OverlayTrigger>
                          <td
                            className="text-success cursor-pointer"
                            onClick={(e) => Act(el.userid, "plus")}
                          >
                            Add 5k
                          </td>

                          <td
                            className="text-danger cursor-pointer"
                            onClick={(e) => Act(el.userid, "minus")}
                          >
                            wipe
                          </td>
                          <td
                            className="text-success cursor-pointer"
                            onClick={(e) => Assd(el.userid)}
                          >
                            {el.dummy ? "Un-Make Dummy" : "Make Dummy"}
                          </td>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                are you sure you want to delete {el.name}'s
                                account ?
                              </Tooltip>
                            }
                          >
                            <td
                              className="text-danger"
                              onClick={(e) => Deluser(el.userid, el.name)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </td>
                          </OverlayTrigger>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center mt-5 text-light">
                    No Players yet
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Players
