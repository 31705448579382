import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import Countdown from 'react-cntdwn';

import Mheader from './Mheader';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import Swal from 'sweetalert2';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaCog, FaSignOutAlt, FaMinus, FaPlus } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { TERipple } from 'tw-elements-react';
import axios from 'axios';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  faDice,
  faGamepad,
  faUser,
  faVolumeMute,
  faInfoCircle,
  faPlay,
  faAngleDoubleDown,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as Buttonr } from 'react-bootstrap';
import CssBaseline from '@mui/material/CssBaseline';
import WebSocketClient from 'websocket';

// import '@fortawesome/fontawesome-free/css/all.min.css';
function money(amount) {
  var moneyFormat = Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  const dr = '₦' + moneyFormat.split('NGN')[1];
  return dr;
}
const Records = ({
  quest,
  setQuest,
  quests,
  setQuests,
  user,
  setUser,
  cont,
  games,
  settings,
  match
}) => {
  const{ qid } = useParams()
  const [questid,setQuestid] = useState(qid)
  const game = games[1];
  const [nroll, setNroll] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [box, setBox] = useState(false);
  const [mute, setMute] = useState(false);
  const jwtt = Cookies.get('jwt');
  const openModal = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 16000); // Adjust the time (in milliseconds) according to your needs
  };
  const [exp, setExp] = useState();

  
  const [newdep, setDep] = useState(1000);
  const [first, setFirst] = useState(false);
  const [newstake, setNewstake] = useState(100);
  const [display, setDisplay] = useState('Staked ' + money(newstake));
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dicea, setDicea] = useState(1);
  const [color, setColor] = useState('fg');
  const [diceb, setDiceb] = useState(1);
  const [onit, setOnit] = useState(false);
  let [pp, setPp] = useState(true);
  const done = '/images/oneee.png';
  const dtwo = '/images/twoo.jfif';
  const dthree = '/images/threes.png';
  const dfour = '/images/ffour.png';
  const dfive = '/images/dice55.jfif';
  const dsix = '/images/six.png';
  const [rolls, setRolls] = useState();
  const [won, setWon] = useState(false);
  const [roll, setRoll] = useState();

  const [pactive, setPactive] = useState(true);

  let newtotal = user.total;

  const [mins, setMins] = useState('');
 

  
  const [vala, setVala] = useState(1000);
  const [grabb, setGrabb] = useState(true);
  const [clients, setClients] = useState(2);

  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };

  
  
  const [navigationCount, setNavigationCount] = useState(0);

  const [openDialogId, setOpenDialogId] = useState(null);

  const handleClosee = () => {
    setOpenDialogId(null);
    handleClose();
  };
  
  const Getupdates = async () => {
    try {
      const response = await axios.get(`${cont.api}recupdate/?usserid=${user.userid}&questid=${questid}`);
      const responseData = response.data;
      if (responseData.success && responseData.user) {
        setUser(responseData.user);

        setQuest(responseData.quest);

        setRolls(responseData.rolls);
        setNroll(responseData.roll);
        // alert(responseData.quests);
        // navigate('/recs');
      } else {
        // Handle other conditions if needed
      }
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
    if (quest) {
      const expiryTime = new Date(quest.toexpirej); // Set the expiry time here
      expiryTime.setMinutes(expiryTime.getMinutes() + 10);
      setExp(expiryTime); // Example: 10 minutes from now
    }
  };

  let intervalId; // Variable to store the interval ID

  let [jj, setJj] = useState(true);
  useEffect(() => {
    if (user && jj) {
      setJj(false);
      Getupdates();

      //   setInterval(()=>{
      //     Getupdates();
      //   },10000)
    }
  }, [user]);

  const Getquests = async (e) => {
    try {
      await axios.get(`${cont.api}quests/${user.userid}`).then((res) => {
        if (res.data.success) {
          if (res.data.user) {
            setQuests(res.data.quests);
            const qqq = res.data.quests;

            const ddd = res.data.user;
            setUser(ddd);
            if (qqq.length > 0) {
              handleOpentt('quests');
            }
          } else {
            // Dalert(res.data.message);
            Swal.fire({
              title: `Connection error`,
              text: `Check your connection`,
              icon: 'error',
            });
          }
        } else {
          // Dalert('. check your network connection');
          Swal.fire({
            title: `error :${res.data.message}`,
            text: `${res.data.message}`,
            icon: 'error',
          });
        }
      });
    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
      Swal.fire({
        title: `Connection error`,
        text: `Check your connection`,
        icon: 'error',
      });
    }
  };
  const cols = [
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
  ];
  const handleOpentt = (id) => {
    setOpenDialogId(id);
  };
  // useEffect(() => {
  //   if (quest) {
  //     const intervalId = setInterval(() => {
  //       const currentTime = new Date();
  //       const expirationTime = new Date(quest.toexpirej);

  //       const remainingTime = expirationTime - currentTime;

  //       // Check if the remaining time is greater than 0
  //       if (remainingTime > 0) {
  //         const minutes = Math.floor(remainingTime / (1000 * 60));
  //         const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  //         setMins(`Expires in ${minutes}m ${seconds}s`);
  //       } else {
  //         // Stop the interval when the timer expires
  //         clearInterval(intervalId);
  //         setMins('Expired !');
          
  //       }
  //     }, 1000);

  //     // Clean up function to clear the interval on component unmount
  //     return () => clearInterval(intervalId);
  //   }
  // }, [quest]);
  
  useEffect(() => {
    const yourFunction = async () => {
      if (user) {
        // alert('yes quest')
        try {
          const response = await axios.get(
            `${cont.api}recrolls/?&qid=${quest.questid}&uid=${user.userid}`
          );
          // alert(user.userid);
          const responseData = response.data;
          if (responseData.success && responseData.user) {
            setUser(responseData.user);

            setQuest(responseData.quest);
            setRolls(responseData.rolls);
            setRoll(responseData.roll);

            // setQuests(responseData.quests);
            // if (rolls.length > 0) {
              
            // } else {
            //   setRolls(false);
            // }
            // alert('set rolls ' + responseData.rolls.length);
            // navigate('/double');
          } else {
            // Handle other conditions if needed
            alert('error');
            // Getupdates();
          }
        } catch (err) {
          console.error(err);
          // Handle error if needed
        }
      }
      else{
        // alert('sgh')
      }
    };
    const intervalId = setInterval(() => {
      // Call your function here
      yourFunction();
    }, 5000);
    // 

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [user]); // Empty dependency array ensures this effect runs only once after the initial render

  // Your function to be called at regular intervals

  return (
    <div>
      <div>
        <CssBaseline />

        <Mheader
          user={user}
          setUser={setUser}
          cont={cont}
          games={false}
          className=""
        />

        {user ? (
          <div className="main-bannerc my-auto jalign">
            <div class="sectionj trending ">
              <div class="container" style={{ height: '120vh' }}>
                <div class="row justify-content-between ">
                  <div class="col-lg-12 mb-0" style={{ height: '70vh' }}>
                    <div class="section-heading mb-0">
                      <div className="caption header-text jbtw">
                        <div className="jbtw jalign align-items-center">
                          <h3 className="text-light tida">Quest Records</h3>{' '}
                          &nbsp;
                        </div>

                        <div className="my-2 mx-1">
                          {quest.expired ? (
                            <div
                              onClick={() => handleOpentt('12')}
                              className="col-md-12 col-12 btn btn-danger  text-light rounded rounded tida"
                            >
                              Learn more ?
                            </div>
                          ) : quest.expired ? (
                            <div
                              onClick={() => handleOpentt('we')}
                              className=" col-12 btn btn-danger  text-light rounded rounded tida"
                            >
                              Expires @{' '}
                              <span className="blinking-text">
                                {quest.toexpire}
                              </span>
                            </div>
                          ) : (
                            <div
                              onClick={() => handleOpentt('12')}
                              className=" col-12 btn btn-danger  text-light rounded rounded tida"
                            >
                              {quest && quest.completed ? (
                                <span className="blinking-text">Completed</span>
                              ) : (
                                <span className="blinking-text">{mins}</span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <Dialog
                        open={openDialogId === '12'}
                        onClose={handleClosee}
                      >
                        <DialogTitle>Quest records ?</DialogTitle>
                        <DialogContent className="text-center">
                          <p>
                            View your latest quest records , wins and losses .
                          </p>
                          <p>
                            When your quest expires , player with highest point
                            wins all , Quest platform takes 10% .
                          </p>
                          <p>
                            when you complete 10 rolls , you are automatically
                            moved here ,where you get to see and wait for quest
                            completion
                          </p>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClosee}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </div>

                    <div
                      className="row justify-content-evenly mx-auto place-items-center"
                      style={{ minHeight: '50vh' }}
                    >
                      {rolls && rolls.length > 0 ? (
                        <div className="table-responsive">
                          <Table
                            // striped
                            // bordered
                            hover
                            responsive
                            size="sm"
                            className="py-3 text-center table rounded"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Grabbs</th>
                                <th>Points</th>
                                <th>Status</th>

                                {/* <th>Result</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {rolls.map((el, index) => (
                                <tr
                                  key={index + 1}
                                  className={[el.won ? ' ' : '', cols[index]]}
                                >
                                  <td>{index + 1}</td>
                                  <td>
                                    {el.userid === user.userid
                                      ? 'You'
                                      : el.name}
                                  </td>
                                  <td>{el.nroll}</td>
                                  <td>{el.rolled}</td>
                                  <td
                                    className={
                                      el.won
                                        ? 'blinking-text text-success'
                                        : el.status === 'in progress'
                                        ? 'text-warning'
                                        : 'text-danger'
                                    }
                                  >
                                    {el.status}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          {/* {quest.completed ? (
                            roll.won ? (
                              <div>
                                <h1 className="text-center text-success blinking-text">
                                  Congratulations ! {`+ ${roll.dwinamount}`}
                                </h1>
                              </div>
                            ) : (
                              <div>
                                <h1 className="text-center text-danger blinking-text">
                                  OOps , you lost !
                                </h1>
                              </div>
                            )
                          ) : (
                            <div>
                              <h3 className="text-center text-light blinking-text">
                                Still in progress
                              </h3>
                            </div>
                          )} */}
                        </div>
                      ) : (
                        <div className="text-center  text-light mt-5">
                          No Records yet
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="jbtw">
                        <Link to="/double">
                          <Buttonr variant="primary">Go back</Buttonr>
                        </Link>
                        <Link to="/history">
                          <Buttonr variant="warning">Quest history</Buttonr>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Records;
