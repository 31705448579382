import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Adminh from './Adminh';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCog, FaSignOutAlt } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  faDice,
  faGamepad,
  faUser,
  faExchangeAlt,
  faHome,
  faUserShield,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import Button from 'react-bootstrap/Button';
// import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { FaAngleDoubleRight } from 'react-icons/fa';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CircularProgress from '@mui/material/CircularProgress';

const Admin = ({
  cont,

  user,
  setUser,
  games,
  data,
  setData,
}) => {
  function money(amount) {
    var moneyFormat = Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    const dr = '₦' + moneyFormat.split('NGN')[1];
    return dr;
  }
  const jwtt = Cookies.get('jwt');
  const [loading, setLoading] = useState(true);

  const [first, setFirst] = useState(false);
  //   setProfits(data.profit)
  const navigate = useNavigate();
  const [things, setThings] = useState({});
  const [clubs, setClubs] = useState({});
  const [logos, setLogos] = useState({});
  const [cname, setCname] = useState('');
  const [avatar, setAvatar] = useState('');
  const [logo, setLogo] = useState('logo');
  const elementRef = useRef(null);

  let [ss, setSs] = useState(true);

  const [ll, setLl] = useState(true);

  useEffect(() => {
    const Check = async () => {
      try {
        await axios.get(`${cont.api}getclubs`, { jwtt }).then((res) => {
          if (res.data.success) {
            // setFirst(false);

            if (res.data.clubs) {
              //   setFirst(false);
              //   alert(res.data.data.latestloginplayertime);
              setClubs(res.data.clubs);
              setLogos(res.data.logos);
              setLogo(logos[0]);
              setLoading(false);
              // setFirst(false);

              // navigate('/admin');
            } else {
              Signout();
            }
          } else {
            Signout();
          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
      }
    };
    let checkCalled = false;

    // Check if jwtt and first are truthy
    if (jwtt && !checkCalled) {
      // Call Check() if conditions are met
      Check();

      // Set the flag to true to indicate that Check() has been called
      checkCalled = true;
    }

    // Cleanup function (runs when component unmounts)
    // return () => {
    //   // Reset the 'first' state when the component unmounts
    //   setFirst(false);
    //   checkCalled = true;

    // };
  }, [jwtt, setData]);

  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Updatelogo = (e) => {
    e.preventDefault();
    setAvatar(e.target.value);
    setLogo(e.target.value);
  };
  const [formData, setFormData] = useState({
    name: '',
    logo: '',

    // Add more fields as needed
  });

  const Createclub = async (e) => {
    e.preventDefault();
    if (ss) {
      setLl(true);
      setSs(false);
      const formDataToSend = new FormData();
      formDataToSend.append('name', cname);
      formDataToSend.append('logo', logo);
    //   alert(formDataToSend.get('name'))
      

      if ((cname.length > 3) && (logo.includes('/'))) {
        try {
          await axios.post(`${cont.api}newclub`, formDataToSend).then((res) => {
            if (res.data.success) {
              if (res.data.exist) {
                // Dalert('Email is in use ');
                Swal.fire({
                  title: `error:<br/>New Club name is in use`,
                  text: `you tried to duplicate a club with  ${formData.name}`,
                  icon: 'error',
                });
              } else {
                setClubs((prev) => res.clubs);
                setCname('');
                setLogo('');
              }
            } else {
              // Dalert('pls check your network connection');
              Swal.fire({
                title: `error:Connection error !`,
                text: `pls check your network connection`,
                icon: 'error',
              });
            }
          });
        } catch (error) {
          // Handle error if needed
          console.error('Error:', error);

          Swal.fire({
            title: `error:Connection error !`,
            text: `pls check your network connection`,
            icon: 'error',
          });
        }
      } else {
        Swal.fire({
          title: `error:Input error!`,
          text: `error:you missed some detail(s) !`,
          icon: 'error',
        });
      }
      setSs(true);
      setLl(false);
      setAvatar('');
      setLogo('');
    }
  };

  return (
    <div>
      <Adminh
        user={user}
        setUser={setUser}
        cont={cont}
        db={true}
        data={data}
        className=""
      />

      <div className="main-bannerb mb-0 ">
        <div class="sectionk trending ">
          <div class="container mt-2">
            <nav
              position="sticky"
              className="main-nav col-12 d-md-none text-light"
            >
              <ul className="nav text-center justify-content-between d-flex">
                <li className="text-light">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id={`tooltip`}>Home</Tooltip>}
                  >
                    <Link
                      to="/"
                      style={{ color: 'white' }}
                      className={[games ? 'active activeb' : '', 'text-light']}
                    >
                      <FontAwesomeIcon icon={faHome} />
                    </Link>
                  </OverlayTrigger>
                </li>

                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip`}>View all transactions</Tooltip>
                    }
                  >
                    <Link style={{ color: 'white' }} to="/atrans">
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id={`tooltip`}>View Players</Tooltip>}
                  >
                    <Link style={{ color: 'white' }} to="/players">
                      <FontAwesomeIcon icon={faUsers} />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip`}>Perform admin duties</Tooltip>
                    }
                  >
                    <Link
                      style={{ color: 'white' }}
                      className="active activeb tida"
                      to="/admin"
                    >
                      <FontAwesomeIcon icon={faUserShield} />
                    </Link>
                  </OverlayTrigger>
                </li>
                {/* <li>
                  <Link style={{ color: 'white' }} to="/settings">
                    <FaCog />
                  </Link>
                </li> */}

                {/* <li className="bg-danger rounded p-2 text-success">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip`}>Total profit </Tooltip>
                    }
                  >
                    <Link
                      style={{ color: 'white', fontStyle: 'normal' }}
                      to="/atrans"
                    >
                      {data.profit}
                    </Link>
                  </OverlayTrigger>
                </li> */}
              </ul>
            </nav>
            <div class="row">
              <div class="col-lg-6">
                <div class="section-heading">
                  {/* <h6>Trending</h6> */}
                  <h2 ref={elementRef} className="text-light tida">
                    Virtual Football
                  </h2>
                </div>
              </div>
              <div class="col-lg-6">
                {/* <div class="main-button">
                    <a href="shop.html">View All</a>
                  </div> */}
              </div>
              {loading ? (
                <div className="mt-5 text-center">
                  <h3 className="text-light blinking-text">
                    loading ...{' '}
                    <Spinner
                      className="mx-auto text-center "
                      animation="border"
                      variant="light"
                      style={{ fontSize: '50px' }}
                    />
                  </h3>
                </div>
              ) : (
                <div>
                  {clubs.length > 0 ? (
                    <div className="row justify-content-evenly mx-auto place-items-center">
                      {clubs.map((el) => (
                        <div class="col-lg-3 col-md-6  ">
                          <div class="item">
                            <div class="thumb text-center ">
                              <div className="py-2">
                                <img
                                  src={el.logo}
                                  className="dimagej"
                                  height="100px"
                                  alt=""
                                  style={{ objectFit: 'contain' }}
                                />
                              </div>
                            </div>
                            <div class="down-content">
                              <span class="category">Football club</span>
                              <h4 className="tidaa">{el.name} </h4>
                              <small>{el.regdate}</small>
                              {/* <Link to="/product-details.html">
                            <FontAwesomeIcon icon={faDice} />
                        </Link> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h3 className="text-muted text-center text-light">
                      No clubs yet !
                    </h3>
                  )}

                  {/* <h3 className="text-center text-light tida">Controls</h3> */}
                  <div className="text-center jbtw my-5">
                    <Link to="/admin" className="bg-secondary">
                      <Button variant="secondary">Back</Button>{' '}
                    </Link>{' '}
                    <Button variant="success" onClick={handleShow}>
                      Add Club
                    </Button>{' '}
                    <Button variant="warning">Club winnings</Button>{' '}
                    <Button variant="info">Virtual Winners</Button>{' '}
                    <Button variant="danger">Manage clubs</Button>{' '}
                  </div>
                </div>
              )}
              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Add new virtual Club</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div
                    style={{ marginTop: '5vh' }}
                    className="form-sectionh fera"
                  >
                    <h2 className=" text-center">Add new Virtual club </h2>
                    <form action="" method="post" onSubmit={Createclub}>
                      <div className="login-boxh mx-auto text-center px-2">
                        <input
                          name="name"
                          type="text"
                          className="email w-75 m2 ele text-center"
                          placeholder="Enter club name"
                          value={cname}
                          onChange={(e) => setCname(e.target.value)}
                        />
                        <img
                          src={logo}
                          className="rounded w-25 text-center mx-auto"
                          alt=""
                        />

                        <select
                          className="form-control m-2 text-center"
                          name="logo"
                          onChange={Updatelogo}
                          id=""
                          value={logo}
                        >
                          {/* <option
                            className="form-control text-center"
                            disabled
                            
                          >
                            ~ Select club logo ~
                          </option> */}
                          {logos && logos.length > 0
                            ? logos.map((image) => (
                                <option selected
                                  className="form-control text-center"
                                  value={image.name}
                                >
                                  {image.name}
                                </option>
                              ))
                            : ''}
                        </select>
                        {/* <div onClick={Alogin}> */}
                        {ll ? (
                          <div className="mx-auto">
                            <button
                              type="submit"
                              className="clkbtn text-light tida"
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div className="mx-auto">
                            <button
                              type="button"
                              className="clkbtn text-light dpapa py-auto"
                            >
                              {/* <CircularProgress className="text-light blinking-text" /> */}
                              <Spinner animation="border" variant="light" />
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
        </div>
      </div>

      {data ? (
        <div className="features mt-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 mx-auto">
                <div>
                  <div className="item text-center border">
                    <span>Latest sign up</span>
                    <h4>{data.newestplayer}</h4>
                    <small>@{data.newestplayertime}</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mx-auto">
                <div>
                  <div className="item text-center border">
                    <span>Latest login</span>
                    <h4>{data.latestloginplayer}</h4>
                    <small>@{data.latestloginplayertime}</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mx-auto">
                <div>
                  <div className="item text-center border">
                    <span>Latest deposit</span>
                    <h4>{data.latestdeposittime}</h4>
                    <small>@{data.newestplayertime}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Admin;
