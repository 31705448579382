import React, {useState,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';
import { useSpring, animated } from 'react-spring';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import Mheader from './Mheader'
import Footer from './Footer'
import Cookies from 'universal-cookie';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Spinner from 'react-bootstrap/Spinner';
import CircularProgress from '@mui/material/CircularProgress';

import Swal from 'sweetalert2';


import './sign.css';

const cookies = new Cookies();

const Signup = ({user,setUser,cont}) => {
  const [cookieValue, setCookieValue] = useState(cookies.get('myCookie') || '');

  const [error, setError] = useState(null);
  
  const [data, setNewdata] = React.useState({});
  const [vcode, setVcode] = useState('');

  const Dalert = (mess)=>{
    setError(mess)
    setTimeout(()=>{setError(null)},6000)
  }
  let [signn, setSign] = useState(true);
  let [ss, setSs] = useState(true);
  const [verifying,setVerifying] = useState(false)
  // let [allowsign, setAllowsign] = useState(false);
  // let [lemail, setLemail] = useState('samuelonwodi@yahoo.com');
  // let [pwrd, setLpwrd] = useState('12345678');
  let [lemail, setLemail] = useState('');
  let [pwrd, setLpwrd] = useState('');
  let [wrongemail, setWrongemail] = useState('');
  // let [track, setTrack] = useState(false);
  let [sname, setSsname] = useState('');
  let [verify, setVerify] = useState(false);
  
  let [semail, setSsemail] = useState('');
  let [spassword, setSspassword] = useState('');
  let [scpassword, setSscpassword] = useState('');
  let [signups, setSignups] = useState({
    name:'',
    email:'',
    password:'',
    cpassword:'',
  });
  const [ll,setLl] =useState(true)
  const navigate = useNavigate();
  let [activeb, setActiveb] = useState(true);
  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1000 }, // Duration of the animation in milliseconds
  });

  
  const Sup = ({user,setUser}) => {
    setSign(false);
    setActiveb(false);
    setVerify(false);

  };
  const Login = (e) => {
    e.preventDefault()
    setSign(true);
    setVerify(false)
    setActiveb(true)
  };
  const Suser = {
    name: 'Samuel Onwodi',
    email: 'samuelonwodi@yahoo.com',
    phone: '+23408130757003',
    total: 5000,
    dtotal: 'NGN 5,000.00',
  };
  // const Fsignup = async (e) => {
  //   e.preventDefault();

  //   try {
  //     // Make a POST request to the backend server
  //     const response = await axios.post(`${cont.api}psignup`,signups); // Replace with your backend server URL and endpoint
  //     console.log('Response from server:', response.data);
  //     // Handle successful response
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //     // Handle error
  //   }
  // }
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    cpassword: '',
    vcode: '',
    // Add more fields as needed
  });

  
  const Updatesign = (e) => {
    e.preventDefault();
    
    const guy = e.target.name;
    setFormData({ ...formData, [guy]: e.target.value })
    setSignups((signups) => ({ ...signups, [guy]: e.target.value }));

    
    const validEmail = semail.includes('@') ? true :false
    const validName = sname.length > 3 ? true : false;
    const validPassword = spassword.length > 7 ? true : false;
    const matchingPasswords = spassword === scpassword ? true : false;

    // if (validName && validEmail && validPassword && matchingPasswords) {
    //   setAllowsign(true);
    // } else {
    //   setAllowsign(false);
    // }

    if (guy === 'email') {
      if (!validEmail) {
        setWrongemail('Enter a valid email');
        // setAllowsign(false);
      } else {
        setWrongemail('');
      }
    }

  };
  // alert(JSON.stringify(data) + " test data");
  const handleSubmit = async (e) => {
    e.preventDefault()
    if(ss){
      setSs(false)
      const formDataToSend = new FormData();
      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      if (
        formData.password === formData.cpassword &&
        formData.password.length > 6
      ) {
        try {
          await axios
            .post(`${cont.api}newsignup`, formDataToSend)
            .then((res) => {
              if (res.data.success) {
                if (res.data.ifUser) {
                  // Dalert('Email is in use ');
                  Swal.fire({
                    title: `error:<br/>Email is in use`,
                    text: `you tried to sign-up with  ${formData.email}`,
                    icon: 'error',
                  });
                } else {
                  setNewdata((prev) => res.data);

                  setVerify(true);
                }
              } else {
                // Dalert('pls check your network connection');
                Swal.fire({
                  title: `error:Connection error !`,
                  text: `pls check your network connection`,
                  icon: 'error',
                });
              }
            });
        } catch (error) {
          // Handle error if needed
          console.error('Error:', error);
          Dalert('pls check your network connection');
        }
      } else {
        Dalert('error:you missed some detail(s) !');
      }
      setSs(true);

    }
  };

  
  
  const Slogin = async (e) => {
    e.preventDefault();
    setLl(false)

    // Create a new FormData object
    const formDataToSend = new FormData();

    // Iterate through the form data and append each field and value to FormData
    for (let key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append('pwrd', pwrd);
    formDataToSend.append('email',lemail);
    
    
    // alert(formDataToSend.get('vcode'));

    console.log(formDataToSend.svcode, formDataToSend.token,data.token +" are  fe tokens");
    
    try {
      await axios.post(`${cont.api}login`, formDataToSend).then((res) => {
        if (res.data.success) {
          if (res.data.user) {
            // Cookies.set('jwt', res.data.token, { expires: 7 });
            // const expirationDate = new Date();
            // expirationDate.setDate(expirationDate.getDate() + 1); // Expires in 1 day
            // cookies.set('user', data.token, { expires: expirationDate });
            // setCookieValue(data.token);
            // setCookieValue(data.token);
            Swal.fire({
              title: `Welcome back ${res.data.user.name}`,
              text: `Last seen :${res.data.user.lastseen}`,
              icon: 'success',
            });
            cookies.set('jwt', res.data.token);

            setUser(res.data.user);

            navigate('/');
          } else {
            // Dalert(res.data.message);
            Swal.fire({
              title: `${res.data.message}`,
              text: `User not found`,
              icon: 'error',
            });
            setSign(true);
            setVerify(false);
            setLl(true);

          }
        } else {
          // Dalert('pls check your network connection');
          Swal.fire({
            title: `error :${res.data.message}`,
            text: `${res.data.message}`,
            icon: 'error',
          });
          setLl(true);

        }
      });

    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
      setLl(true);
      Dalert('pls check your network connection');
    }
    
  };
  const handleVerify = async (e) => {
    e.preventDefault();
    if(!verifying){
      setVerifying(true);

      // Create a new FormData object
      const formDataToSend = new FormData();

      // Iterate through the form data and append each field and value to FormData
      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      formDataToSend.append('svcode', data.vcode);
      formDataToSend.append('token', data.token);
      formDataToSend.append('vcode', vcode);

      // alert(formDataToSend.get('vcode'));

      console.log(
        formDataToSend.svcode,
        formDataToSend.token,
        data.token + ' are  fe tokens'
      );

      try {
        await axios.post(`${cont.api}verify`, formDataToSend).then((res) => {
          if (res.data.success) {
            if (res.data.user) {
              Swal.fire({
                title: `Hi ${res.data.user.name} , your registeration was successful`,
                text: `Welcome to the land of opportunities`,
                icon: 'success',
              });
              setUser(res.data.user);
              const expirationDate = new Date();
              expirationDate.setDate(expirationDate.getDate() + 1); // Expires in 1 day
              cookies.set('user', data.token, { expires: expirationDate });
              setCookieValue(data.token);
              navigate('/');
            } else {
              Swal.fire({
                title: `${res.data.message}`,
                text: `We are experiencing difficulties verifying your  account at this moment due to the above error message`,
                icon: 'error',
              });
              // setSign(true);
              setVerify(false);
              setVerifying(false);

            }
          } else {
            if (res.data.message) {
              Swal.fire({
                title: `${res.data.message}`,
                text: `We are experiencing difficulties verifying your  account at this moment due to the above error message`,
                icon: 'error',
              });
              // setSign(true);
              setVerify(false);
              setVerifying(false);
              setVcode('');
            } else {
              Swal.fire({
                title: `Sign up error`,
                text: `pls check your network connection`,
                icon: 'error',
              });
              // setSign(true);
              setVerify(false);
              setVcode('');
              setVerifying(false);

            }
          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
        setVerifying(false);

        Dalert('pls check your network connection');
      }
    }
    
  };


  // const Alogin = () => {
  //   setUser(Suser);
  //   navigate('/');
    
  // };
  
  return (
    <div className="bg-primary ">
      <div className="bbodyd " style={{ background: 'url(/img/slider.jpg)' }}>
        <Mheader user={false} />

        <p
          className="text-light  text-center blink mt-3"
          style={{
            textShadow: '2px 2px 6px black',
            fontWeight: '40px',
            letterSpacing: '9px',
          }}
        >
          life is a game
        </p>
        <div className="row ">
          <div className="col-4 d-none d-md-flex">
            <animated.div style={props}>
              <img
                src="/images/emb.png"
                className=" d-noneh d-md-block"
                alt=""
              />
            </animated.div>
          </div>
          <div className="containerr bg-primary col-4  align-items-center">
            <div className={('slider', signn ? 'moveslider' : '')}></div>
            <div className="btnn px-3 ">
              <button
                className={
                  activeb
                    ? 'activeb login text-light tida'
                    : 'login  text-light '
                }
                onClick={Login}
              >
                Login
              </button>
              <button
                className={
                  !activeb
                    ? 'activeb login text-light tida'
                    : 'login  text-light '
                }
                onClick={Sup}
              >
                Signup
              </button>
            </div>

            <div style={signn? { marginTop:'13vh'}:{marginTop:'5vh'}}
              className={
                ('form-section fera',
                signn ? 'form-section-move ' : '')
              }
            >
              {!!signn ? (
                <form action="" method="post" onSubmit={Slogin}>
                  <div className="login-box">
                    <input
                      type="email"
                      className="email ele text-center"
                      placeholder="youremail@email.com"
                      value={lemail}
                      onChange={(e) => setLemail(e.target.value)}
                    />

                    <input
                      type="password"
                      className="password ele text-center"
                      placeholder="password"
                      value={pwrd}
                      onChange={(e) => setLpwrd(e.target.value)}
                    />
                    {/* <div onClick={Alogin}> */}
                    {ll ? (
                      <div>
                        <button className="clkbtn text-light tida">
                          Login
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          type="button"
                          className="clkbtn text-light dpapa py-auto"
                        >
                          {/* <CircularProgress className="text-light blinking-text" /> */}
                          <Spinner animation="border" variant="light" />
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              ) : (
                ''
              )}

              {!signn ? (
                verify ? (
                  <form onSubmit={handleVerify}>
                    <div className="signup-box">
                      <h2 style={{ textShadow: '2px 2px 2px white' }}>
                        Enter Verification Code
                      </h2>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="vcode"
                          required
                          value={vcode}
                          name="vcode"
                          onChange={(e) => setVcode((prev) => e.target.value)}
                          className="text-center email ele "
                          placeholder="verification code"
                          autoFocus={true}
                          style={{ letterSpacing: '8px', fontSize: '20px' }}
                        />
                        <p className="position-absolute text-center text-light col-12">
                          A verification code was sent to {formData.email}
                        </p>
                      </div>

                      <div className="position-relative col-8 mb-1">
                        {error && (
                          <Alert
                            variant="danger"
                            className=" mx-right position-absolute "
                          >
                            {error || 'An error occurred.'}
                          </Alert>
                        )}
                      </div>

                      {/* {allowsign ? ( */}
                      <button
                        className={[
                          verifying ? 'blinking-text' : '',
                          'text-center clkbtn text-light tida blinking-text',
                        ]}
                      >
                        {verifying ? 'Verifying' : 'Verify'}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div className="signup-box">
                        <input
                          type="text"
                          value={signups.name}
                          id="name"
                          name="name"
                          onChange={Updatesign}
                          className="text-center name ele"
                          placeholder="Enter your name"
                          required
                        />
                        <div className="position-relative">
                          <input
                            type="email"
                            id="email"
                            required
                            name="email"
                            value={signups.email}
                            onChange={Updatesign}
                            className="text-center email ele"
                            placeholder="youremail@email.com"
                          />
                          {/* <p className="position-absolute text-center text-danger col-12">
                            {wrongemail}
                          </p> */}
                        </div>

                        <div className="mb-0">
                          <input
                            type="password"
                            className="text-center password ele"
                            placeholder="enter password (min 7 chars)"
                            id="password"
                            required
                            value={signups.password}
                            name="password"
                            onChange={Updatesign}
                          />
                          {/* <p className="mb-0 text-center text-light text-info">
                            password length > 7
                          </p> */}
                        </div>
                        <input
                          type="password"
                          id="cpassword"
                          required
                          value={signups.cpassword}
                          name="cpassword"
                          onChange={Updatesign}
                          className="text-center password ele"
                          placeholder="Confirm password"
                        />
                        <div className="position-relative col-8 mb-1">
                          {error && (
                            <Alert
                              variant="danger"
                              className=" mx-right position-absolute "
                            >
                              {error || 'An error occurred.'}
                            </Alert>
                          )}
                        </div>

                        {ss ? (
                          <button className="text-center clkbtn text-light tida">
                            Signup
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="text-center dpapa clkbtn text-light"
                          >
                            <CircularProgress className="text-light blinking-text" />
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                )
              ) : (
                ''
              )}
            </div>
            
          </div>
          <div className="col-3 d-none d-md-flex text-center jbtw">
            <div className="right-image text-center mx-auto jbtw">
              <img src="/images/dice.gif" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
