import React from 'react';


import {Link} from 'react-router-dom'

export default function App() {
  return (
    <div
      className="text-center text-white  mt-5 mb-0"
      style={{ backgroundColor: '#caced1' }}
    >
      <div
        className="text-center p-3 bg-primary"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
      >
        <Link className="text-white" to="/">
          emperor6.com.ng{' '}
        </Link>
        © {new Date().getFullYear() + '  '}
      </div>
    </div>
  );
}