import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';
import Mheader from './Mheader';
import Footer from './Footer';
import { useSpring, animated } from 'react-spring';
import TextLoop from 'react-text-loop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCog, FaSignOutAlt } from 'react-icons/fa';
import {
  faDice,
  faGamepad,
  faUser,
  faExchangeAlt,
  faHome,
  faUserShield,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Cookies from 'js-cookie';
// import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FaAngleDoubleRight } from 'react-icons/fa';

const About = ({ cont, user, setUser, games, settings }) => {
  function money(amount) {
    var moneyFormat = Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    const dr = '₦' + moneyFormat.split('NGN')[1];
    return dr;
  }
  const jwtt = Cookies.get('jwt');
  const [first, setFirst] = useState(false);

  const navigate = useNavigate();
  const [things, setThings] = useState({});
  const elementRef = useRef(null);
  const Check = async () => {
    try {
      await axios.post(`${cont.api}ifuser`, { jwtt }).then((res) => {
        if (res.data.success) {
          // setFirst(false);

          if (res.data.user) {
            setFirst(false);

            setUser(res.data.user);
            setFirst(false);

            navigate('/');
          } else {
            Signout();
          }
        } else {
          Signout();
        }
      });
    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (jwtt && !user) {
      Check();
      setFirst(false);
    }
  }, [jwtt, Check, navigate, first]);

  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1000 }, // Duration of the animation in milliseconds
  });
  const propsb = useSpring({
    from: { opacity: 0, transform: 'translateX(-150px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 500 }, // Duration of the animation in milliseconds
  });
  const Signout = () => {
    setUser(false);
    Cookies.remove('jwt');
    navigate('/');
  };

  return (
    <div>
      {/* <Header
        user={user}
        setUser={setUser}
        cont={cont}
        games={true}
        className=""
      /> */}
      <Mheader
        user={user}
        setUser={setUser}
        cont={cont}
        about={true}
        className=""
      />

      <div className="main-banner p-0 mb-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <div className="caption header-text">
                <h2 className="mb-3 tida">About Us </h2>

                <p>
                  At Emperor6, we're passionate about revolutionizing the online
                  gaming experience. Our platform is designed to provide users
                  with an unparalleled gaming environment where they can stake,
                  play, and win exciting rewards. Founded by a team of gaming
                  enthusiasts and tech experts, MikP Tech Innovations aims to
                  redefine the way people engage with online gaming. With
                  Emperor6, we've created a cutting-edge platform that offers a
                  diverse range of games, from classic favorites to innovative
                  new releases. What sets Emperor6 apart is our commitment to
                  fairness, transparency, and security. We prioritize user
                  satisfaction and strive to ensure that every gaming experience
                  is enjoyable, rewarding, and free from any concerns about
                  fairness or security. Whether you're a seasoned gamer or just
                  looking for some fun and excitement, Emperor6 has something
                  for everyone. Join us today and experience the thrill of
                  online gaming like never before!
                </p>
                <animated.div style={propsb}>
                  <div className="mx-auto text-md-end text-center mb-3">
                    {user ? (
                      <Link
                        to="/"
                        className="col-md-4 col-6 btn btn-danger  text-light rounded border-light tida"
                      >
                        Let's ride <FaAngleDoubleRight />
                      </Link>
                    ) : (
                      <Link
                        to="/sign"
                        className="col-md-4 col-6 btn btn-danger  text-light rounded border-light tida"
                      >
                        Let's ride <FaAngleDoubleRight />
                      </Link>
                    )}
                  </div>
                </animated.div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default About
