import React, { useEffect, useState, useRef } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Mheader from "./Mheader";
import { useSpring, animated } from "react-spring";
import TextLoop from "react-text-loop";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaCog, FaSignOutAlt } from "react-icons/fa";
import Cookies from "js-cookie";
import { TERipple } from "tw-elements-react";
import Table from "react-bootstrap/Table";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Footer from "./Footer";
import Spinner from "react-bootstrap/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faGamepad,
  faVolumeMute,
  faPlay,
  faDice,
} from "@fortawesome/free-solid-svg-icons";
function money(amount) {
  var moneyFormat = Number(amount).toLocaleString("en-US", {
    style: "currency",
    currency: "NGN",
  });
  const dr = "₦" + moneyFormat.split("NGN")[1];
  return dr;
}
const Trans = ({ user, setUser, cont, games, settings }) => {
  const jwtt = Cookies.get("jwt");
  const [pp, setPp] = useState(true);
  const [loading, setLoading] = useState(true);
  const [trans, setTrans] = useState([]);
  const [first, setFirst] = useState(true);
  const [start, setStart] = useState([0]);
  let [wd, setWd] = useState({
    bankname: user.bankname,
    bankaccount: user.bankaccount,
    amount: 5000,
    userid: user.userid,
  });
  const { userid } = useParams();
  
  const [deposits, setDeposits] = useState([]);
  const [withs, setWiths] = useState([]);
  // const [transactions,setTransactions] = useState()
  const navigate = useNavigate();

  useEffect(() => {
    const Check = async () => {
      try {
        await axios
          .get(`${cont.api}afterp/${user?user.userid : userid}`)
          .then((res) => {
            if (res.data.success) {
              if (res.data.user) {
                setUser(res.data.user);
                const nd = res.data.trans.filter((el)=> el.deposit)
                const wd = res.data.trans.filter((el)=> !el.deposit)
                setTrans(res.data.trans);
                setDeposits([...nd]);
                setWiths([...wd]);
                //   alert(res.data.trans)
                setFirst(false);
                setLoading(false);

                //   navigate('/trans');
              } else {
                Signout();
              }
            } else {
              Signout();
            }
          });
      } catch (error) {
        // Handle error if needed
        console.error("Error:", error);
      }
    };
    if (jwtt && first) {
      Check();
    } else if (!user) {
      navigate("/");
    }
  }, [navigate, jwtt, user]);
  
  const [newdep, setDep] = useState(1000);
  const [withd, setWith] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [showModalb, setShowModalb] = useState(false);

  const elementRef = useRef(null);

  const Pay = async (e) => {
    e.preventDefault();
    if (pp) {
      if (Number(newdep) > 99) {
        setPp(false);
        try {
          const formDataToSend = new FormData();

          formDataToSend.append("userid", user.userid);
          formDataToSend.append("gameid", games[0].gameid);
          formDataToSend.append("amount", newdep);
          formDataToSend.append("api", cont.api);
          formDataToSend.append("fe", cont.fe);

          await axios
            .post(`${cont.api}newdeposit`, formDataToSend)
            .then((res) => {
              if (res.data.success) {
                window.location.href = res.data.url;
              } else {
                // Dalert('pls check your network connection');
                Swal.fire({
                  title: `error :We are unable to proceed with your payment`,
                  text: `try again later`,
                  icon: "error",
                });

              }
              setPp(true);
              setShowModal(false);
            });
        } catch (error) {
          // Handle error if needed
          console.error("Error:", error);
          Swal.fire({
            title: `Connection error`,
            text: `Check your connection`,
            icon: "error",
          });
          setPp(true);
          setShowModal(false);
        }
      } else {
        Swal.fire({
          title: `Sorry you cant deposit less than ₦100.00`,
          text: `Deposit too low`,
          icon: "error",
        });
        setPp(true);
        setShowModal(false);
      }
    }
  };
  const Iwithdraw = async (e) => {
    e.preventDefault();
    if (pp) {
      if (Number(wd.amount) <= user.total) {
        setPp(false);
        try {
          const formDataToSend = new FormData();

          formDataToSend.append("userid", user.userid);
          formDataToSend.append("amount", wd.amount);
          formDataToSend.append("bankname", wd.bankname);
          formDataToSend.append("bankaccount", wd.bankaccount);
          formDataToSend.append("api", cont.api);
          formDataToSend.append("fe", cont.fe);

          await axios
            .post(`${cont.api}withdraw`, formDataToSend)
            .then((res) => {
              if (res.data.success) {
                if (!res.data.low) {
                  setUser(res.data.user);
                  const nd = res.data.trans.filter((el) => el.deposit);
                  const wd = res.data.trans.filter((el) => !el.deposit);
                  setTrans(res.data.trans);
                  setDeposits([...nd]);
                  setWiths([...wd]);
                  //   alert(res.data.trans)
                  setFirst(false);
                  setLoading(false);

                  //   navigate('/trans');
                } else {
                  Swal.fire({
                    title: `Sorry your account balance is too low for this amount `,
                    text: `Account balance too low`,
                    icon: "error",
                  });
                  setPp(true);
                  setShowModalb(false);
                }
                setPp(true);
                setShowModalb(false);
              } else {
                // Dalert('pls check your network connection');
                Swal.fire({
                  title: `error :We are unable to proceed with your payment`,
                  text: `try again later`,
                  icon: "error",
                });
              }
              setPp(true);
              setShowModalb(false);
            });
        } catch (error) {
          // Handle error if needed
          console.error("Error:", error);
          Swal.fire({
            title: `Connection error`,
            text: `Check your connection`,
            icon: "error",
          });
          setPp(true);
          setShowModalb(false);
        }
      } else {
        Swal.fire({
          title: `Sorry your account balance is too low for this amount `,
          text: `Account balance too low`,
          icon: "error",
        });
        setPp(true);
        setShowModalb(false);
      }
    }
  };
  const Signout = () => {
    setUser(false);
    Cookies.remove("jwt");
    navigate("/");
  };
  

  return (
    <div className="">
      <Mheader
        user={user}
        setUser={setUser}
        cont={cont}
        trans={true}
        className=""
      />

      <div className="main-bannerc mb-0 ">
        <div class="sectionj mt-2 trending ">
          <div class="container" style={{ minHeight: "90vh" }}>
            <nav className="main-nav col-12 d-md-none text-light">
              <ul className="nav text-center justify-content-between d-flex">
                <li className="text-light">
                  <Link
                    to="/"
                    style={{ color: "white" }}
                    className="text-light"
                  >
                    <FontAwesomeIcon icon={faGamepad} />
                  </Link>
                </li>

                <li>
                  {/* <a href={contact}>Accounts</a> */}
                  <Link style={{ color: "white" }} to="/accounts">
                    <FontAwesomeIcon icon={faUser} />
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "white" }} to="/settings">
                    <FaCog />
                  </Link>
                </li>

                <li className="bg-danger rounded p-2 text-success">
                  <Link
                    style={{ color: "white", fontStyle: "normal" }}
                    to="/trans"
                  >
                    {money(user.total)}
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: "white" }}
                    onClick={Signout}
                    className={settings ? "active activeb" : ""}
                  >
                    <FaSignOutAlt className="text-danger" />
                  </Link>
                </li>
              </ul>
            </nav>
            <div class="section-headingj d-flex justify-content-between">
              {/* <h6>Trending</h6> */}
              <h3 className="text-light tida">My Transactions</h3>
            </div>
            <div className="div px-1">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 px-2 text-center bg-transparent jcenter align-items-center"
              >
                <Tab
                  eventKey="home"
                  className=" col-12"
                  title={
                    <span className="custom-tab-title bg-primary w-100 px-5 py-2  ">
                      Deposits
                    </span>
                  }
                >
                  <div class="d-flex align-items-center  my-2">
                    <div class="col-12 text-end">
                      <div className="d-flex justify-content-end text-info">
                        {!showModal ? (
                          <button
                            className="btn clkbtn text-light tida col-2 col-md-2"
                            onClick={() => setShowModal(true)}
                          >
                            Deposit
                          </button>
                        ) : (
                          ""
                        )}

                        {showModal && (
                          <div className="modalf position-absolute z-3">
                            <div className="modal-content position-absolute  text-center">
                              <div className="row br align-items-center col-md-5 bg-primary  mx-auto p-5 mt-1">
                                <div className="">
                                  {pp ? (
                                    <div>
                                      <h2 className="text-light tida">
                                        Enter amount
                                      </h2>
                                      ₦{" "}
                                      <input
                                        type="number"
                                        name=""
                                        id=""
                                        value={newdep}
                                        className="text-center border-0 rounded"
                                        style={{ outline: "none" }}
                                        onChange={(e) => setDep(e.target.value)}
                                      />
                                    </div>
                                  ) : (
                                    <h2 className="text-light tida">
                                      Initiating transaction
                                      <span className="blinking-text">
                                        {" "}
                                        ...
                                      </span>
                                    </h2>
                                  )}
                                </div>
                                <div className=" ">
                                  <br />
                                  {pp ? (
                                    <button
                                      onClick={Pay}
                                      className="text-center  clkbtn text-light tida"
                                    >
                                      Proceed
                                    </button>
                                  ) : (
                                    <button className="text-center blinking-text clkbtn text-light tida">
                                      On it ...
                                    </button>
                                  )}
                                  <br />
                                  <br />
                                  {pp ? (
                                    <button
                                      onClick={() => setShowModal(false)}
                                      className="text-center p-0 clkbtn text-warning "
                                    >
                                      Cancel
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <h3 className="text-light tida">Deposit(s)</h3>
                  {loading ? (
                    <div className="mt-5 text-center">
                      <h3 className="text-light blinking-text">
                        loading ...{" "}
                        <Spinner
                          className="mx-auto text-center "
                          animation="border"
                          variant="light"
                          style={{ fontSize: "50px" }}
                        />
                      </h3>
                    </div>
                  ) : (
                    <div
                      className="table-container table-responsive rela justify-content-center  mx-auto rounded mt-1 col-12 text-light"
                      style={{
                        maxHeight: "70vh",
                        minHeight: "40vh",
                        overflow: "scroll",
                      }}
                    >
                      {deposits && deposits.length > 0 ? (
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          className="py-3 text-center table overflow-auto rounded "
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Reference</th>
                              <th>Amount</th>
                              {/* <th>Type</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {deposits.map((el, index) => (
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{el.date}</td>
                                <td>{el.reference}</td>
                                <td>{el.damount}</td>
                                {/* <td
                                  className={
                                    el.deposit
                                      ? "text-success font-weight-bolder"
                                      : "text-danger font-weight-bolder"
                                  }
                                >
                                  {el.deposit ? "Deposit" : "Withdrawal"}
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center  text-light">
                          No deposit(s) yet
                        </div>
                      )}
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="profile"
                  className=" col-12"
                  title={
                    <span className="custom-tab-title bg-primary w-100 px-5 py-2  ">
                      Withdrawals
                    </span>
                  }
                >
                  <div class="d-flex align-items-center  my-2">
                    <div class="col-12 text-end">
                      <div className="d-flex justify-content-end text-info">
                        {user.pending ? (
                          <button className="btn nal clkbtn text-secondary tida col-2 col-md-2">
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id={`tooltip`}>
                                  You currently have a pending withdrawal{" "}
                                </Tooltip>
                              }
                            >
                              <h6
                                elementRef={elementRef}
                                className="nal text-light text-center"
                              >
                                Withdraw
                              </h6>
                            </OverlayTrigger>
                          </button>
                        ) : (
                          <button
                            className="btn clkbtn text-light tida col-2 col-md-2"
                            onClick={() => setShowModalb(true)}
                          >
                            Withdraw
                          </button>
                        )}

                        {showModalb && (
                          <div className="modalf position-absolute z-3">
                            <div className="modal-content position-absolute  text-center">
                              <form
                                onSubmit={Iwithdraw}
                                className="row br align-items-center col-md-5 bg-primary  mx-auto p-5 mt-1"
                              >
                                <h5 className="text-center">
                                  Initiate Withdrawal
                                </h5>
                                <div className="">
                                  {pp ? (
                                    <div className="">
                                      <div className="mb-1">
                                        <h2 className="text-light tida">
                                          Enter amount
                                        </h2>{" "}
                                        ₦{" "}
                                        <input
                                          type="number"
                                          name=""
                                          id=""
                                          required
                                          min="5000"
                                          value={wd.amount}
                                          className="text-center border-0 rounded"
                                          style={{ outline: "none" }}
                                          onChange={(e) =>
                                            setWd({
                                              ...wd,
                                              amount: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="mb-1">
                                        <h4 className="text-light tida">
                                          Bank Name
                                        </h4>{" "}
                                        <input
                                          type="text"
                                          name=""
                                          id=""
                                          required
                                          value={wd.bankname}
                                          className="text-center border-0 rounded"
                                          style={{ outline: "none" }}
                                          onChange={(e) =>
                                            setWd({
                                              ...wd,
                                              bankname: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="mb-1">
                                        <h4 className="text-light tida">
                                          Account Number
                                        </h4>{" "}
                                        <input
                                          type="number"
                                          name=""
                                          required
                                          id=""
                                          value={wd.bankaccount}
                                          className="text-center border-0 rounded"
                                          style={{ outline: "none" }}
                                          onChange={(e) =>
                                            setWd({
                                              ...wd,
                                              bankaccount: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <h2 className="text-light tida">
                                      Initiating withdrawal
                                      <span className="blinking-text">
                                        {" "}
                                        ...
                                      </span>
                                    </h2>
                                  )}
                                </div>
                                <div className="mb-2 jbtw pt-5">
                                  {pp ? (
                                    <button
                                      type="submit"
                                      className="text-center  clkbtn text-light tida"
                                    >
                                      Withdraw
                                    </button>
                                  ) : (
                                    <button className="text-center blinking-text clkbtn text-light tida">
                                      On it ...
                                    </button>
                                  )}

                                  {pp ? (
                                    <button
                                      onClick={() => setShowModalb(false)}
                                      className="text-center p-0 clkbtn text-warning "
                                    >
                                      Cancel
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <h3 className="text-light tida">Withdrawal(s)</h3>
                  {loading ? (
                    <div className="mt-5 text-center">
                      <h3 className="text-light blinking-text">
                        loading ...{" "}
                        <Spinner
                          className="mx-auto text-center "
                          animation="border"
                          variant="light"
                          style={{ fontSize: "50px" }}
                        />
                      </h3>
                    </div>
                  ) : (
                    <div
                      className="table-container table-responsive rela justify-content-center  mx-auto rounded mt-1 col-12 text-light"
                      style={{
                        maxHeight: "70vh",
                        minHeight: "40vh",
                        overflow: "scroll",
                      }}
                    >
                      {withs && withs.length > 0 ? (
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          className="py-3 text-center table overflow-auto rounded "
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Reference</th>
                              <th>Bank</th>
                              <th>Account number</th>
                              <th>Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withs.map((el, index) => (
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{el.date}</td>
                                <td>{el.reference}</td>
                                <td>{el.bankname}</td>
                                <td>{el.bankaccount}</td>
                                <td>{el.damount}</td>
                                <td>
                                  {el.pending ? (
                                    <h5 className="text-warning">Pending</h5>
                                  ) : el.failed ? (
                                    <h5 className="text-danger">failed</h5>
                                  ) : (
                                    <h5 className="text-success">successful</h5>
                                  )}
                                </td>
                                {/* <td
                                  className={
                                    el.deposit
                                      ? "text-success font-weight-bolder"
                                      : "text-danger font-weight-bolder"
                                  }
                                >
                                  {el.deposit ? "Deposit" : "Withdrawal"}
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center  text-light">
                          No Withdrawal(s) yet
                        </div>
                      )}
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Trans;
